import React, { useState, useEffect } from "react";
import labor from "../../assests/images/labor.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import Swal from "sweetalert2"; // Import SweetAlert2

import { addStatus } from "../../services/admin/manageworkforce/manageworkforceservices";

export default function ManageManPowerDetail() {
  const location = useLocation();
  const navigate = useNavigate();

  const [enquiryDetails, setEnquiryDetails] = useState([]);

  // Destructure rowData from location.state with a null check
  const { rowData } = location?.state || {};
  console.log(rowData, "row data sunny");

  // Initialize state with default value
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (rowData) {
      setCompleted(rowData.status === "Completed"); // Ensure status check is case-sensitive

      // Extract enquiryDetails if available
      if (rowData.enquiryDetails && rowData.enquiryDetails.length > 0) {
        setEnquiryDetails(rowData.enquiryDetails);
      }
    }
  }, [rowData]);

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    console.log("Checkbox checked state:", isChecked);

    // Show confirmation popup using SweetAlert2
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the status to completed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#02bea8",
      cancelButtonColor: "#5d5d5d",
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        // Prepare the payload with the ID (_id)
        const payload = { _id: rowData._id };

        // Call addStatus with the payload and _id
        await addStatus({ body: payload, _id: rowData._id });
        setCompleted(isChecked); // Update the state based on the checkbox state
        console.log("Status updated successfully.");

        // Notify success and redirect
        await Swal.fire("Updated!", "Status has been updated.", "success");
        navigate(adminPathUrl.manageManpower); // Redirect after success
      } catch (error) {
        console.error("Error updating status:", error);
        // Revert checkbox state if the update fails
        setCompleted(!isChecked);

        // Notify error
        await Swal.fire(
          "Error!",
          "There was an error updating the status.",
          "error"
        );
      }
    } else {
      // If user cancels, revert the checkbox to the previous state
      e.target.checked = completed;
    }
  };

  const handleCancel = () => {
    navigate(adminPathUrl.manageManpower);
  };

  if (!rowData) {
    return <div>No enquiry data found.</div>;
  }

  return (
    <section>
      <div className="container">
        <PageTile
          heading1={"Details"}
          backButton={true}
          skillfiltervalue={location?.state?.skillfiltervalue}
          statusfiltervalue={location?.state?.statusfiltervalue}
          termfiltervalue={location?.state?.termfiltervalue}
          countryvalue={location?.state?.countryvalue}
          redirectUrl="/admin/man-power-enqiury"
        />
        <div className="prev-detail">
          {/* Conditionally render the checkbox based on status */}
          {rowData?.status !== "Completed" && (
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                style={{
                  width: "1.3em",
                  height: "1.3em",
                  margin: ".2em",
                  padding: ".2em",
                  border: "1px solid #ccc",
                  opacity: "3",
                }}
                className="form-check-input"
                id="markCompleted"
                checked={completed}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" htmlFor="markCompleted">
                Mark as Completed
              </label>
            </div>
          )}
          <div className="prev-detail-card">
            <div className="row mt-4">
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Name</p>
                  <span>{rowData.fullName}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Email</p>
                  <span>{rowData.email}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Contact Number</p>
                  <span>{rowData.contactNumber}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Tenure</p>
                  <span>
                    {rowData.tenure === "long_term"
                      ? "Long Term"
                      : rowData.tenure}
                  </span>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Company Name</p>
                  <span>{rowData.companyName || "Not Provided"}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Accommodation is Taken Care</p>
                  <span>{rowData.accommodation}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Transportation</p>
                  <span>{rowData.transportation}</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="prev-card-detail">
                  <p>Message</p>
                  <span>{rowData.message}</span>
                </div>
              </div>
            </div>

            {/* Duration and Workers Section */}
            {enquiryDetails.length > 0 && (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="prev-card-detail">
                    <p>Duration and Workers</p>
                    {enquiryDetails.map((detail, index) => (
                      <div key={detail._id || index} className="mb-2">
                        <div>
                          <strong>Category:</strong>{" "}
                          {detail?.tertiarySkillName || "Not Provided"}
                        </div>
                        <div>
                          <strong>
                            Duration:
                            {detail.days
                              ? " (in days)"
                              : detail.months
                              ? " (in months)"
                              : " "}
                          </strong>
                          <span>
                            {detail.days
                              ? `${detail.days} days`
                              : detail.months
                              ? `${detail.months} `
                              : "Not Provided"}
                          </span>
                        </div>
                        <div>
                          <strong>Workers:</strong>{" "}
                          {detail.workers || "Not Provided"}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Display Worker Details */}
        {rowData?.tenure === "long_term" && (
          <div className="prev-detail mt-4">
            <h3>Worker Details</h3>
            <div className="row">
              {rowData?.userDetails?.map((user, index) => (
                <div className="col-md-6 mb-3" key={index}>
                  <div className="row bg-light p-3 me-2 shadow rounded align-items-center">
                    <div className="col-md-4 position-relative">
                      <img
                        style={{ borderRadius: "10px" }}
                        className="img-fluid"
                        src={labor}
                        alt="Worker"
                      />
                    </div>
                    <div className="col-md-8">
                      <div className="modal-detail-content">
                        <h1>{user.fullName}</h1>
                        <br />
                        <h2>{user.nationality}</h2>
                        <h3 className="border-top">Skills with Experience</h3>
                        <div className="modal-detail-content-personal pt-3 border-top">
                          Age: <span>{user.age}</span> <br />
                          Passport: <span>{user.is_passport}</span> <br />
                          Phone:{" "}
                          <span>
                            {user.countryCode} {user.phone}
                          </span>{" "}
                          <br />
                          Language: <span>{user.language}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
