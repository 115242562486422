import React, { useEffect, useState } from "react";
import "./dashboard.css"; // Import CSS file for styling
import Card from "./card";
import BarChartComponent from "./barchart";
import { CgNotes } from "react-icons/cg";
import { BiCategory } from "react-icons/bi";
import ManageManpowerEnquiryList from "./manageWorkForce";
import { getAdminDashboard } from "../../services/admin/manageworkforce/manageworkforceservices";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    monthlyworkforceEnquiry: [],
    workforceEnquiry: 0,
    datalakeUser: 0,
  });
  const [selectedYear1, setSelectedYear1] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await getAdminDashboard();
        if (response.status) {
          const monthlyworkforceEnquiry =
            response.monthlyworkforceEnquiry || [];
          setDashboardData({
            ...response,
            monthlyworkforceEnquiry,
          });
          const years = [
            ...new Set(monthlyworkforceEnquiry.map((item) => item.year)),
          ];
          if (years.length > 0) setSelectedYear1(years[0]);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  if (!dashboardData || !Array.isArray(dashboardData.monthlyworkforceEnquiry)) {
    return <div>Loading...</div>;
  }

  const { monthlyworkforceEnquiry } = dashboardData;
  const years = [...new Set(monthlyworkforceEnquiry.map((item) => item.year))];
  const filteredData = monthlyworkforceEnquiry.filter(
    (item) => item.year === selectedYear1
  );

  return (
    <div className="dashboard_page">
      <div className="row">
        <div className="col-md-4 p-relative">
          <Card
            title="Manpower Enquiry"
            subtitle={dashboardData?.workforceEnquiry}
            content="Some quick example text to build on the card title and make up the bulk of the card's content."
          />
          <span className="dash-icons">
            <CgNotes />
          </span>
        </div>
        <div className="col-md-4 p-relative">
          <Card
            title="Verified Profiles"
            subtitle={dashboardData?.workforce} // Adjust according to your data
            content="Some quick example text to build on the card title and make up the bulk of the card's content."
          />
          <span className="dash-icons">
            <BiCategory />
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-2">
          <div className="px-5 py-3 bg-light shadow rounded">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="my-4">Manpower Enquiry</h3>
              <div className="filtersec">
                <label htmlFor="year-select-1">Select Year:</label>
                <select
                  id="year-select-1"
                  value={selectedYear1 || ""}
                  onChange={(e) => setSelectedYear1(parseInt(e.target.value))}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <BarChartComponent data={filteredData} year={selectedYear1} />
          </div>
        </div>

        <ManageManpowerEnquiryList />
      </div>
    </div>
  );
};

export default Dashboard;
