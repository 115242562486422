import React, { useEffect, useState } from "react";
import { Table, Form } from "react-bootstrap";
import { years } from "../../utils/helpers";
import { ErrorMessage } from "formik";
import { getTertiarySkills } from "../../services/admin/manageworkforce/manageworkforceservices";
import Spinner from "react-bootstrap/Spinner";

const SkillsSelectionAdd = ({
  onSelect,
  errName,
  errComp = "div",
  errClass = "error text-center",
  style = { color: "red", textAlign: "center", marginBottom: "20px" },
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSkills, setSelectedSkills] = useState({});
  const [skills, setSkills] = useState([]);

  const fetchSkillset = async () => {
    try {
      const data = await getTertiarySkills({ body: null, type: "data_lake" });
      setSkills(data.data);
    } catch (error) {
      console.error("Error fetching skillset:", error);
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (skillId) => {
    const updatedSkills = { ...selectedSkills };
    const skill = skills.find((skill) => skill._id === skillId);

    // If the skill was previously unchecked, select it and set its experience to 0
    if (!updatedSkills[skillId]) {
      updatedSkills[skillId] = { ...skill, checked: true, experience: 0 };
    } else {
      // If it was already checked, uncheck it
      updatedSkills[skillId].checked = !updatedSkills[skillId].checked;
      if (!updatedSkills[skillId].checked) {
        updatedSkills[skillId].experience = 0;
      }
    }

    setSelectedSkills(updatedSkills);

    // Send the updated skill list to parent with the correct structure
    onSelect(
      Object.values(updatedSkills)
        .filter((skill) => skill.checked)
        .map((skill) => ({
          _id: skill._id,
          subSkillId: skill.subSkillId,
          tertiarySkillName: skill.tertiarySkillName,
          isActive: skill.isActive,
          checked: skill.checked,
          experience: skill.experience,
        }))
    );
  };

  // Handle year change for experience
  const handleYearChange = (skillId, event) => {
    const updatedSkills = { ...selectedSkills };
    updatedSkills[skillId].experience = parseInt(event.target.value);
    setSelectedSkills(updatedSkills);

    // Send the updated skill list with experience to parent
    onSelect(
      Object.values(updatedSkills)
        .filter((skill) => skill.checked)
        .map((skill) => ({
          _id: skill._id,
          subSkillId: skill.subSkillId,
          tertiarySkillName: skill.tertiarySkillName,
          isActive: skill.isActive,
          checked: skill.checked,
          experience: skill.experience,
        }))
    );
  };

  // Handle search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter skills based on the search term
  const filteredSkills = skills.filter((skill) =>
    skill.tertiarySkillName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    fetchSkillset();
  }, []);
  return (
    <>
      <Form.Control
        type="text"
        placeholder="Search for a skill..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-3"
      />
      <div style={{ overflowY: "auto", height: "200px" }}>
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Skill</th>
              <th>Experience</th>
            </tr>
          </thead>
          <tbody>
            {filteredSkills?.length > 0 ? (
              filteredSkills?.map((skill) => {
                const isChecked = selectedSkills[skill._id]?.checked || false;
                const experience = selectedSkills[skill._id]?.experience || 0;
                return (
                  <tr key={skill._id}>
                    <td>
                      <div className="d-flex" style={{ width: "200px" }}>
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(skill._id)}
                          checked={isChecked}
                          className="mx-2"
                          style={{ opacity: 1 }}
                        />
                        <span>{skill.tertiarySkillName}</span>
                      </div>
                    </td>
                    <td>
                      <Form.Select
                        onChange={(event) => handleYearChange(skill._id, event)}
                        value={experience}
                        disabled={!isChecked}
                      >
                        <option value={0}>No Experience</option>
                        {years.map(({ label, value }, idx) => (
                          <option value={value} key={idx}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="pt-3 text-center">
                <td colSpan={5}>
                  <Spinner animation="grow" size="sm" />
                  <Spinner animation="grow" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <ErrorMessage
        name={errName}
        component={errComp}
        className={errClass}
        style={style}
      />
    </>
  );
};

export default SkillsSelectionAdd;
