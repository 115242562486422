import React from "react";
import "./title.css";
import BackButton from "../../BackButton";
function PageTile({
  heading1,
  heading2,
  backButton,
  location_id,
  handleBackClick,
  redirectUrl,
  skillfiltervalue,
  experiencefiltervalue,
  countryvalue,
  termfiltervalue,
  statusfiltervalue,
}) {
  return (
    <>
      <div className="d-flex">
        {backButton && (
          <BackButton
            location_id={location_id}
            redirectUrl={redirectUrl}
            skillfiltervalue={skillfiltervalue}
            experiencefiltervalue={experiencefiltervalue}
            countryvalue={countryvalue}
            termfiltervalue={termfiltervalue}
            statusfiltervalue={statusfiltervalue}
          />
        )}
        {heading1 && <h1 className="heading1">{heading1}</h1>}
      </div>
      {heading2 && <h2 className="heading2">{heading2}</h2>}
    </>
  );
}

export default PageTile;
