import { Autocomplete, TextField } from "@mui/material";
import React from "react";

const CustomSearchDropdown = ({
  label = "Skill",
  data = [],
  onChange = () => {},
  value = "",
}) => {
  return (
    <Autocomplete
      disablePortal
      options={data}
      // sx={{ width: 400 }}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      value={value}
      renderInput={(params) => (
        <TextField className="testing" {...params} label={label} />
      )}
    />
  );
};

export default CustomSearchDropdown;
