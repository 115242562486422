import React, { useEffect, useState } from "react";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import { toast } from "react-toastify";
import {
  addPrivacyPolicy,
  getCMSContentsService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";

function GulfJobLocation() {
  const [jobLocationContent, setJobLocationContent] = useState("");
  const [aboutUsContent, setAboutUsContent] = useState("");
  const [isWeplaced, setIsWeplaced] = useState(false);

  const MAX_LENGTH_JOB_LOCATION = 200;
  const MAX_LENGTH_ABOUT_US = 1500;

  const handleJobLocationChange = (value) => {
    if (value?.length <= MAX_LENGTH_JOB_LOCATION) {
      setJobLocationContent(value);
    } else {
      toast.error(
        `Job Location cannot exceed ${MAX_LENGTH_JOB_LOCATION} characters.`
      );
    }
  };

  const handleAboutUsChange = (value) => {
    if (value?.length <= MAX_LENGTH_ABOUT_US) {
      setAboutUsContent(value);
    } else {
      toast.error(`About Us cannot exceed ${MAX_LENGTH_ABOUT_US} characters.`);
    }
  };

  useEffect(() => {
    const fetchCMSContents = async () => {
      try {
        const response = await getCMSContentsService();
        if (response.status) {
          setJobLocationContent(response.data[1].job_location);
          setAboutUsContent(response.data[1].about_us);
          setIsWeplaced(response.data[1].is_weplaced === "true");
        } else {
          console.error("Failed to fetch CMS contents:", response.message);
        }
      } catch (error) {
        console.error("Error fetching CMS contents:", error);
      }
    };

    fetchCMSContents();
  }, []);

  const handleSubmit = async (section) => {
    try {
      let payload = {};
      if (section === "job_location") {
        payload = {
          job_location: jobLocationContent,
          is_weplaced: isWeplaced,
        };
      } else if (section === "about_us") {
        payload = {
          about_us: aboutUsContent,
          is_weplaced: isWeplaced,
        };
      }

      const response = await addPrivacyPolicy(payload);
      if (response.status) {
        toast.success(`${section} updated successfully!`);
      } else {
        toast.error(`Failed to update ${section}: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section}:`, error);
    }
  };

  return (
    <div>
      <div className="manage_privacy_policy_page card p-4 rounded shadow">
        <PageTile heading1={"Job Location"} />
        <CommonEditor
          value={jobLocationContent}
          onChange={handleJobLocationChange}
          maxLength={MAX_LENGTH_JOB_LOCATION}
        />
        <div className="py-3">
          <button
            className="global_admin_btn"
            onClick={() => handleSubmit("job_location")}
          >
            Save
          </button>
        </div>
      </div>

      {/* <div className="manage_privacy_policy_page card p-4 rounded shadow">
        <PageTile heading1={"About Us"} />
        <CommonEditor
          value={aboutUsContent}
          onChange={handleAboutUsChange}
          maxLength={MAX_LENGTH_ABOUT_US}
        />
        <div className="py-3">
          <button
            className="global_admin_btn"
            onClick={() => handleSubmit("about_us")}
          >
            Save
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default GulfJobLocation;
