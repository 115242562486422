import React, { useEffect, useState } from "react";
import "../../pages/cart/index.css";
import labor from "../../assests/images/labor.jpg";
import doc1 from "../../assests/admin-images/docs/degree.jpg";
import doc2 from "../../assests/admin-images/docs/passport.jpg";
import doc3 from "../../assests/admin-images/docs/resume.jpg";
import { useLocation, useNavigate } from "react-router-dom";
import { getWorkerDetail } from "../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import Modal from "../../components/imageModal";
import { FaEdit } from "react-icons/fa";
import { adminPathUrl } from "../../shared/pathUrl";

export default function WorkerForceDetail() {
  console.log("gulfedit");
  const [editIndex, setEditIndex] = useState(-1);
  const navigate = useNavigate();
  const [imageSources, setImageSources] = useState([doc1, doc2, doc3]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  // const location = useLocation();
  const [selectedImage, setSelectedImage] = useState(null); // State to manage the image popup
  const location = useLocation();

  // console.log(location.state?.currentPage, "(location.state?.currentPage");
  // const handleEditClick = (index) => {
  //   setEditIndex(index);
  //   document.getElementById(`file-input-${index}`).click();
  // };

  const handleEditClick = () => {
    if (data) {
      navigate(adminPathUrl.editDataWorkforce, {
        state: {
          id: data._id,
          profile_pic: data.profile_pic,
          fullName: data.fullName,
          skill: data.skill || [],
          nationality: data.nationality,
          city: data.city || "",
          age: data.age || "",
          language: data.language || "",
          is_passport: data.is_passport || "",
          countryCode: data.countryCode || "",
          phone: data.phone || "",
          redirecturl: "/admin/gulf-woker-force-detail",
        },
      });
    } else {
      toast.error("No data available to edit.");
    }
  };

  console.log(location, "gulflol");

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newImageSources = [...imageSources];
        newImageSources[index] = reader.result;
        setImageSources(newImageSources);
        setEditIndex(-1);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoading(true);
        const body = {
          user_id: location.state.id,
        };
        const response = await getWorkerDetail(
          body,
          location?.state?.deleteUser
        );
        if (response && response.data) {
          setData(response?.data?.data);
        } else {
          toast.error("No News or Article data available.");
        }
      } catch (error) {
        toast.error("Error fetching News or Article.");
      } finally {
        setLoading(false);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <section>
      <div className="container">
        <div className="prev-detail">
          <PageTile
            heading1={"Worker Details"}
            backButton={true}
            skillfiltervalue={location?.state?.skillfiltervalue}
            experiencefiltervalue={location?.state?.experiencefiltervalue}
            redirectUrl={
              location?.state?.isHighlightUser == "rejectuser"
                ? "/admin/manage-rejected-profile"
                : location?.state?.isHighlightUser == "deleteuser"
                ? "/admin/maange-delete-profile"
                : location?.state?.isHighlightUser == "gulfWorkforce"
                ? "/admin/gulfWorkforce"
                : ""
            }
          />

          <div className="row">
            <div className="col-md-4">
              <div className="position-relative text-center py-5 bg-light shadow rounded">
                <div className="m-0 d-flex align-items-center gap-2 text-end justify-content-end">
                  <button
                    onClick={handleEditClick}
                    className="bg-transparent border-0 me-2"
                  >
                    <FaEdit size={24} />
                  </button>
                </div>
                <div className="profileName">
                  <img className="" src={data?.profile_pic} alt="Worker" />
                </div>
                <div className="modal-detail-content">
                  <h1>{data?.fullName}</h1>
                  <h2>
                    {data?.skill?.[0]?.SubSkillName} | {data?.nationality}
                  </h2>
                  <h3 className="border-top">Skills with Experience</h3>
                  {data?.skill.map((skill, index) => (
                    <div className="modal-detail-content-exp pb-3" key={index}>
                      {skill?.SubSkillName} ({skill?.experience}) <br />
                    </div>
                  ))}
                  <div className="modal-detail-content-personal pt-3 border-top">
                    Age: <span>{data?.age}</span> <br />
                    Language: <span>{data?.language}</span> <br />
                    Passport:{" "}
                    <span className="text-capitalize">
                      {data?.is_passport}
                    </span>{" "}
                    <br />
                    Phone:{" "}
                    <span className="text-capitalize">{data?.phone}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="doc-card position-relative p-2 bg-light shadow rounded h-100">
                <div className="image-edit-section">
                  <h3>Documents</h3>
                  <div className="row">
                    {data?.documents?.map((item, index) => (
                      <div key={index} className="col-md-4">
                        <div className="position-relative bg-light shadow rounded 1">
                          <img
                            style={{
                              borderRadius: "10px",
                              cursor: "pointer",
                              objectFit: "initial",
                            }}
                            className="img-fluid w-100"
                            src={item?.user_document}
                            alt={`Document ${index}`}
                            onClick={() =>
                              setSelectedImage(item?.user_document)
                            } // Show image in modal
                          />
                          <p className="text-center">{item?.doc_title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {selectedImage && (
        <Modal
          imageSrc={selectedImage}
          onClose={() => setSelectedImage(null)}
        />
      )}
    </section>
  );
}
