import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PageTile from "../../../../components/pageTitle/pageTitle/PageTile";
import {
  addNewsArticle,
  getskillset,
} from "../../../../services/admin/manageworkforce/manageworkforceservices";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { adminPathUrl } from "../../../../shared/pathUrl";
import Loader from "../../../../components/loader/loader";
import MaxLimit from "../../../../components/MaxLimit";
import { toast } from "react-toastify";

function AddNewsArticle() {
  const [media, setMedia] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [skillsetData, setSkillsetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  console.log(skillsetData, "skillsetData");

  const initialValues = {
    title: "",
    content: "",
    newsArticle_pic: null,
    skillName: "",
    you_might_like: false,
  };

  const handleCancel = () => {
    navigate(adminPathUrl.manageBlogs);
  };

  const MAX_LENGTHS = {
    content: 6000,
    title: 20,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    content: Yup.string().required("Content is required"),
    newsArticle_pic: Yup.mixed().required("Media is required"),
    you_might_like: Yup.boolean().required("You might like is required"),
  });

  const handleLengthCheck = (value, maxLength, fieldName) => {
    if (value.length >= maxLength) {
      toast.warning(`Maximum length reached for ${fieldName}`);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await addNewsArticle(values);
      if (response.status) {
        navigate(-1);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleMediaChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type.split("/")[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setMedia(reader.result);
        setMediaType(fileType);
        setFieldValue("newsArticle_pic", file);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        setLoading(true);
        const response = await getskillset({ body: null, type: "" });
        if (response && response.data) {
          setSkillsetData(response.data);
        }
      } catch (error) {
        console.error("Error fetching skillset:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSkillset();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add News and Article"} backButton={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="form-group">
              <label htmlFor="skillName">Skill</label>
              <Field
                as="select"
                name="skillName"
                className="form-control appearance-show"
              >
                <option value="">Select option(Optional)</option>
                {skillsetData &&
                  skillsetData.map((item, index) => (
                    <option key={index} value={item?.newSkillName}>
                      {item?.newSkillName}
                    </option>
                  ))}
              </Field>
            </div>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <MaxLimit current={values.title.length} max={MAX_LENGTHS.title} />
              <Field
                type="text"
                name="title"
                maxLength={MAX_LENGTHS.title}
                className="form-control"
                onChange={(e) => {
                  handleLengthCheck(e.target.value, MAX_LENGTHS.title, "Title");
                  setFieldValue("title", e.target.value);
                }}
              />
              <ErrorMessage
                name="title"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="you_might_like"
                  className="form-check-input opacity-1"
                  id="you_might_like"
                />
                <label className="form-check-label" htmlFor="you_might_like">
                  You might like
                </label>
              </div>
              <ErrorMessage
                name="you_might_like"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="media-upload-section mb-4">
              <button
                type="button"
                className="btn btn-primary green_bg me-2"
                onClick={() => document.getElementById("imageUpload").click()}
              >
                Upload New Image
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => document.getElementById("videoUpload").click()}
              >
                Upload New Video
              </button>
              <input
                type="file"
                accept="image/*"
                id="imageUpload"
                onChange={(e) => handleMediaChange(e, setFieldValue)}
                className="d-none"
              />
              <input
                type="file"
                accept="video/*"
                id="videoUpload"
                onChange={(e) => handleMediaChange(e, setFieldValue)}
                className="d-none"
              />
              {media && (
                <div className="media-preview mt-3">
                  {mediaType === "video" ? (
                    <video
                      controls
                      src={media}
                      style={{
                        width: "50%",
                        maxHeight: "400px",
                        objectFit: "cover",
                      }}
                      alt="Uploaded Video"
                    >
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <img
                      src={media}
                      alt="Uploaded"
                      style={{
                        width: "50%",
                        maxHeight: "400px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              )}
              <ErrorMessage
                name="newsArticle_pic"
                component="div"
                className="text-danger mt-4"
              />
            </div>
            <div className="form-group">
              <label htmlFor="content">Content</label>
              <MaxLimit
                current={values.content.length}
                max={MAX_LENGTHS.content}
              />
              <Field
                as="textarea"
                name="content"
                maxLength={MAX_LENGTHS.content}
                className="form-control"
                rows={8}
                onChange={(e) => {
                  handleLengthCheck(
                    e.target.value,
                    MAX_LENGTHS.content,
                    "Content"
                  );
                  setFieldValue("content", e.target.value);
                }}
              />
              <ErrorMessage
                name="content"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="py-3">
              <button type="submit" className="global_admin_btn">
                Save
              </button>
              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddNewsArticle;
