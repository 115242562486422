import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Pagination from "../pagination/pagination";
import { useLocation, useNavigate } from "react-router-dom";

const DataTableComponent = ({
  title,
  data,
  columns,
  selectedRows,
  clickedrow,
  checkboxchange,
  downloadBtns = false,
  downloadCSV = () => {},
  downloadPDF = () => {},
  toRedirectPage = null,
  pagination,
  setPagination,
  conditionalRowStyles,
}) => {
  const { currentPage, totalPages, limit, totalCount } = pagination || {};
  const itemsPerPage = limit;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data;

  const totalItems = totalCount;
  const startItem = indexOfFirstItem + 1;
  const endItem = Math.min(indexOfLastItem, totalItems);
  const showPagination = totalItems > itemsPerPage;
  const location = useLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState({ csvLoader: false, pdfLoader: false });
  const handlePageChange = (pageNumber) => {
    setPagination((prev) => {
      return {
        ...prev,
        currentPage: pageNumber,
      };
    });

    // Create a new state object without mutating the original location.state
    const newState = { ...location.state, currentPage: pageNumber };

    // Navigate to the same path with the new state
    navigate(location.pathname, { state: newState });
  };
  useEffect(() => {
    if (toRedirectPage) {
      setPagination((prev) => {
        return {
          ...prev,
          currentPage: toRedirectPage,
        };
      });
    }
  }, [toRedirectPage]);
  return (
    <div>
      <Card style={{ border: "none", position: "relative" }}>
        <div
          style={{ padding: "20px", position: "relative", maxHeight: "1000px" }}
        >
          <div
            className="px-2 card_title"
            style={{
              position: "sticky",
              top: "0",
              zIndex: "99",
              backgroundColor: "#fff",
            }}
          >
            {title}
            {isNaN(startItem)
              ? null
              : `(${startItem}-${endItem} of ${totalItems})`}
            <div className="d-flex justify-content-end">
              {showPagination && (
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  paginate={handlePageChange}
                />
              )}
            </div>
            {downloadBtns && (
              <div className="d-flex justify-content-end download_btns">
                <button
                  className="global_admin_btn me-2"
                  disabled={loader.pdfLoader}
                  onClick={async () => {
                    setLoader((prev) => ({
                      ...prev,
                      pdfLoader: true,
                    }));
                    await downloadPDF();
                    setLoader((prev) => ({
                      ...prev,
                      pdfLoader: false,
                    }));
                  }}
                >
                  {loader.pdfLoader ? "Downloading...." : "Download PDF"}
                </button>
                <button
                  onClick={async () => {
                    setLoader((prev) => ({
                      ...prev,
                      csvLoader: true,
                    }));
                    await downloadCSV();
                    setLoader((prev) => ({
                      ...prev,
                      csvLoader: false,
                    }));
                  }}
                  filename={"Manpower Enquiry.csv"}
                  className="global_admin_btn"
                  disabled={loader.csvLoader}
                >
                  {loader.csvLoader ? "Downloading...." : "Download CSV"}
                </button>
              </div>
            )}
          </div>

          <DataTable
            className="table_block mt-3"
            highlightOnHover
            responsive
            selectableRowsHighlight
            onRowClicked={clickedrow}
            onSelectedRowsChange={checkboxchange}
            columns={columns}
            pagination={false} // Disable default pagination
            customStyles={{
              table: {
                style: {
                  border: "1px solid white",
                },
              },
              headCells: {
                style: {
                  color: "#A8A8BD",
                  fontSize: 16,
                },
              },
              rows: {
                style: {
                  borderBottom: "1px solid #C5C5D3",
                  padding: "20px 15px",
                },
              },
            }}
            data={currentItems}
            conditionalRowStyles={conditionalRowStyles}
          />

          {/* Render Pagination component only if there are more than itemsPerPage entries */}
          <div className="d-none">
            {showPagination && (
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                paginate={handlePageChange}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default DataTableComponent;
