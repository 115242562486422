import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import WorkerListHeader from "../components/workerListHeader";
import CartHeader from "../components/cartHeader";
import { pathUrl } from "../shared/pathUrl";
import { useSelector } from "react-redux";
import { IoLogoWhatsapp } from "react-icons/io";

//common layout file
export default function Layout() {
  const location = useLocation();
  const { userId } = useSelector((state) => state.MANPOWERENQUIRY);
  return (
    <>
      {/* condition rendering of the header as per the path name of the app */}
      {location.pathname === pathUrl.workerList ? (
        <WorkerListHeader />
      ) : location.pathname === pathUrl.cart ? (
        <CartHeader userId={userId} />
      ) : (
        <Header />
      )}
      <Outlet />
      {/* add custom whatup icon */}
      <div
        style={{
          position: "fixed",
          right: "30px",
          bottom: "30px",
          zIndex: "99",
        }}
        className="whatsup_style"
      >
        <a
          href="https://api.whatsapp.com/send/?phone=971543503030&text&type=phone_number&app_absent=0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IoLogoWhatsapp size={60} color="#02bea8" />
        </a>
      </div>
      {location.pathname !== pathUrl.homePage && <Footer />}
    </>
  );
}
