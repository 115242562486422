import { Table, Form } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { getTertiarySkills } from "../../../services/admin/manageworkforce/manageworkforceservices";
import { years } from "../../../utils/helpers";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
const CustomFormikSkillsSelectionUpdate = ({
  existingskills,
  skilldata,
  setFieldValue,
  errName,
  errComp = "div",
  errClass = "error text-center",
  style = { color: "red", textAlign: "center", marginBottom: "20px" },
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    fetchSkillset();
  }, []);
  // Fetch skillset
  // const fetchSkillset = async () => {
  //   try {
  //     const response = await getTertiarySkills({
  //       body: null,
  //       type: "data_lake",
  //     });
  //     if (existingskills?.length > 0) {
  //       const updatedData = response?.data?.map((item) => {
  //         const matchedInner = existingskills?.find(
  //           (inner) => item._id === inner?.tertiarySkillId
  //         );
  //         if (matchedInner) {
  //           return {
  //             ...item,
  //             checked: true,
  //             experience: parseInt(matchedInner?.experience),
  //           };
  //         }
  //         return { ...item, checked: false, experience: 0 };
  //       });
  //       setFieldValue("skill", updatedData || []);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching skillset:", error);
  //   }
  // };

  const fetchSkillset = async () => {
    try {
      const response = await getTertiarySkills({
        body: null,
        type: "data_lake",
      });

      if (existingskills?.length > 0) {
        const updatedData = response?.data?.map((item) => {
          const matchedInner = existingskills?.find(
            (inner) => item._id === inner?.tertiarySkillId
          );
          if (matchedInner) {
            return {
              ...item,
              checked: true,
              experience: parseInt(matchedInner?.experience),
            };
          }
          return { ...item, checked: false, experience: 0 };
        });

        // Sort the updatedData to move checked items to the top
        const sortedData = updatedData.sort((a, b) => {
          if (a.checked && !b.checked) return -1;
          if (!a.checked && b.checked) return 1;
          return 0; // Keeps the order for items with the same checked state
        });

        setFieldValue("skill", sortedData || []);
      }
    } catch (error) {
      console.error("Error fetching skillset:", error);
    }
  };

  // Handle checkbox selection
  const handleCheckboxChange = (skill) => {
    const updatedSkills = skilldata?.map((s) => {
      if (s?._id === skill?._id) {
        return {
          ...s,
          checked: !s.checked,
          experience: !s.checked ? s.experience : 0,
        };
      } else {
        return s;
      }
    });
    setFieldValue("skill", updatedSkills);
  };
  // Handle year change for experience
  const handleYearChange = (skill, event) => {
    const updatedSkills = skilldata.map((s) => {
      if (s?._id === skill?._id) {
        return {
          ...s,
          experience: parseInt(event.target.value),
        };
      } else {
        return s;
      }
    });
    setFieldValue("skill", updatedSkills);
  };
  // Handle search input
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  // Filter skills based on the search term
  const filteredSkills = skilldata?.filter((skill) =>
    skill?.tertiarySkillName?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <Form.Control
        type="text"
        placeholder="Search for a skill..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-3"
      />
      {skilldata && skilldata?.length > 0 ? (
        <div style={{ overflowY: "auto", height: "200px" }}>
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Skill</th>
                <th>Experience</th>
              </tr>
            </thead>
            <tbody>
              {filteredSkills?.map((skill) => (
                <tr key={skill._id}>
                  <td>
                    <div className="d-flex" style={{ width: "200px" }}>
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(skill)}
                        className="mx-2"
                        checked={skill?.checked}
                        style={{ opacity: 1 }}
                      />
                      <span>{skill.tertiarySkillName}</span>
                    </div>
                  </td>
                  <td>
                    <Form.Select
                      onChange={(event) => handleYearChange(skill, event)}
                      value={skill?.experience || 0}
                      disabled={!skill.checked}
                    >
                      <option value={0}>No Experience</option>
                      {years.map(({ label, value }, idx) => (
                        <option value={value} key={idx}>
                          {label}
                        </option>
                      ))}
                    </Form.Select>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="d-flex justify-content-center pt-3 pb-4">
          {" "}
          <Spinner animation="grow" size="sm" />
          <Spinner animation="grow" />
        </div>
      )}

      <ErrorMessage
        name={errName}
        component={errComp}
        className={errClass}
        style={style}
      />
    </>
  );
};
export default CustomFormikSkillsSelectionUpdate;
