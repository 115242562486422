import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { updateSkillsetWithSubSkills } from "../../services/admin/manageworkforce/manageworkforceservices";
import Switch from "react-switch";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";
import { Accordion } from "react-bootstrap";

const initialValues = {
  newSkillName: "",
  isActive: true,
  subSkills: [],
};

const findDifferences = (original, updated) => {
  const differences = {
    newSkillName: {
      newSubSkillName: updated.newSkillName,
      _id: original._id,
      isActive: updated.isActive,
    },
  };

  differences.subSkills = updated.subSkills
    .map((subSkill, index) => {
      const originalSubSkill = original.subSkills[index];
      const changedSubSkill = {
        ...subSkill,
        tertiarySkills: [],
      };

      let hasSubSkillChanged = false;

      if (
        subSkill.newSubSkillName !== originalSubSkill.newSubSkillName ||
        subSkill.isActive !== originalSubSkill.isActive
      ) {
        hasSubSkillChanged = true;
        changedSubSkill.newSubSkillName = subSkill.newSubSkillName;
        changedSubSkill.isActive = subSkill.isActive;
      }

      subSkill.tertiarySkills.forEach((tertiarySkill, tIndex) => {
        const originalTertiarySkill = originalSubSkill.tertiarySkills[tIndex];

        if (
          tertiarySkill.tertiarySkillName !==
            originalTertiarySkill.tertiarySkillName ||
          tertiarySkill.isActive !== originalTertiarySkill.isActive
        ) {
          changedSubSkill.tertiarySkills.push(tertiarySkill);
        }
      });

      return hasSubSkillChanged || changedSubSkill.tertiarySkills.length > 0
        ? changedSubSkill
        : null;
    })
    .filter(Boolean);

  return differences;
};

const UpdateCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state.row;
  const [initState, setInitState] = useState(initialValues);

  useEffect(() => {
    setInitState(data);
  }, [data]);

  const validationSchema = Yup.object().shape({
    newSkillName: Yup.string().required("Category is required"),
    isActive: Yup.boolean(),
    subSkills: Yup.array().of(
      Yup.object().shape({
        newSubSkillName: Yup.string().required("Sub category name is required"),
        isActive: Yup.boolean(),
        tertiarySkills: Yup.array().of(
          Yup.object().shape({
            tertiarySkillName: Yup.string().required(
              "Tertiary skill name is required"
            ),
            isActive: Yup.boolean(),
          })
        ),
      })
    ),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const differences = findDifferences(initState, values);
    try {
      const response = await updateSkillsetWithSubSkills(differences);
      if (response?.status === true) {
        toast.success(response?.message);
        navigate(-1);
      } else {
        toast.error(response?.response?.data?.message);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1="Update category" backButton={true} />
      <Formik
        initialValues={initState}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="newSkillName">Category Name</label>
                <div className="d-flex align-items-center mb-2 form-control p-0">
                  <Field
                    type="text"
                    name="newSkillName"
                    className="w-100 p-3 mb-0 border-0"
                    value={values.newSkillName}
                    onChange={handleChange}
                    disabled={!values.isActive}
                  />
                  <Switch
                    className="switch_active me-2"
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={(checked) => {
                      setFieldValue("isActive", checked);
                    }}
                    checked={values.isActive}
                  />
                </div>
                <ErrorMessage
                  name="newSkillName"
                  component="span"
                  className="text-danger"
                />
              </div>
            </div>

            <FieldArray name="subSkills">
              {({ push, remove }) => (
                <div className="row mt-3">
                  <label className="col-12" htmlFor="subSkills">
                    Sub Categories
                  </label>
                  {values.subSkills.map((subSkill, index) => (
                    <div className="col-md-6 mb-3" key={index}>
                      <Accordion className="custom-accordion">
                        <Accordion.Item eventKey={index.toString()}>
                          <Accordion.Header>
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <span>
                                {subSkill.newSubSkillName || "Sub Category"}
                              </span>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="d-flex justify-content-between align-items-center w-100 form-control p-0">
                              <Field
                                name={`subSkills[${index}].newSubSkillName`}
                                className="border-0 w-100 p-3 me-2"
                                placeholder="Sub Category Name"
                                disabled={
                                  !subSkill.isActive || !values.isActive
                                }
                              />
                              <Switch
                                className="switch_active me-2"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                onChange={(checked) =>
                                  setFieldValue(
                                    `subSkills[${index}].isActive`,
                                    checked
                                  )
                                }
                                checked={subSkill.isActive}
                                disabled={!values.isActive}
                              />
                            </div>

                            <ErrorMessage
                              name={`subSkills[${index}].newSubSkillName`}
                              component="span"
                              className="text-danger"
                            />

                            {/* FieldArray for Tertiary Skills */}
                            <FieldArray
                              name={`subSkills[${index}].tertiarySkills`}
                            >
                              <div className="mt-3">
                                <label>Tertiary Skills</label>
                                {subSkill.tertiarySkills.map(
                                  (tertiary, tIndex) => (
                                    <div key={tIndex} className="mb-3">
                                      <div className="d-flex justify-content-between align-items-center w-100 form-control  p-0">
                                        <Field
                                          name={`subSkills[${index}].tertiarySkills[${tIndex}].tertiarySkillName`}
                                          className=" me-2 border-0 w-100 p-3 me-2"
                                          placeholder="Tertiary Skill Name"
                                          disabled={
                                            !tertiary.isActive ||
                                            !subSkill.isActive ||
                                            !values.isActive
                                          }
                                        />
                                        <Switch
                                          className="switch_active me-2"
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          onChange={(checked) =>
                                            setFieldValue(
                                              `subSkills[${index}].tertiarySkills[${tIndex}].isActive`,
                                              checked
                                            )
                                          }
                                          checked={tertiary.isActive}
                                          disabled={
                                            !subSkill.isActive ||
                                            !values.isActive
                                          }
                                        />
                                      </div>
                                      <ErrorMessage
                                        name={`subSkills[${index}].tertiarySkills[${tIndex}].tertiarySkillName`}
                                        component="span"
                                        className="text-danger"
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            </FieldArray>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            <div className="py-3">
              <button
                type="submit"
                className="global_admin_btn me-2"
                disabled={isSubmitting}
              >
                Update
              </button>
              <button
                type="button"
                className="global_admin_btn1 btn btn-secondary py-2 px-3"
                onClick={() => navigate(-1)}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateCategory;
