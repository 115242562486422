import React, { useState, useEffect } from "react";
import "./pagination.css";

const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  paginate,
  maxPagesToShow = 5,
}) => {
  const [jumpPage, setJumpPage] = useState(currentPage);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    setJumpPage(currentPage);
  }, [currentPage]);

  let startPage, endPage;

  if (totalPages <= maxPagesToShow) {
    startPage = 1;
    endPage = totalPages;
  } else {
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    if (currentPage <= halfMaxPagesToShow) {
      startPage = 1;
      endPage = maxPagesToShow;
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = totalPages - maxPagesToShow + 1;
      endPage = totalPages;
    } else {
      startPage = currentPage - halfMaxPagesToShow;
      endPage = currentPage + halfMaxPagesToShow;
    }
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const handleJumpPageChange = (e) => {
    setJumpPage(e.target.value);
  };

  const handleJumpPageSubmit = () => {
    const page = Math.min(Math.max(parseInt(jumpPage), 1), totalPages); // Ensure the page is within bounds
    if (!isNaN(page)) {
      paginate(page);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleJumpPageSubmit();
    }
  };

  return (
    <nav>
      <ul className="pagination justify-content-center">
        <li className={`page-item ${currentPage === 1 && "disabled"}`}>
          <button
            className="page-link"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            aria-label="Previous Page"
          >
            Previous
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number && "active"}`}
          >
            <button
              onClick={() => paginate(number)}
              className="page-link"
              aria-label={`Go to page ${number}`}
            >
              {number}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
          <button
            className="page-link"
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
            aria-label="Next Page"
          >
            Next
          </button>
        </li>
        <div data-toggle="tooltip" data-placement="top" title="Jump Page">
          <input
            className="w-auto text-center border ms-2"
            type="number"
            placeholder="Jumppage"
            value={jumpPage}
            onChange={handleJumpPageChange}
            onKeyDown={handleKeyDown}
            onBlur={handleJumpPageSubmit}
            min="1"
            max={totalPages}
            style={{ height: "33px" }}
          />
        </div>
      </ul>
    </nav>
  );
};

export default Pagination;

// import React, { useState } from "react";
// import "./pagination.css";

// const Pagination = ({
//   currentPage,
//   itemsPerPage,
//   totalItems,
//   paginate,
//   maxPagesToShow = 5,
// }) => {
//   const totalPages = Math.ceil(totalItems / itemsPerPage);
//   // Calculate the range of page numbers to display
//   let startPage, endPage;

//   if (totalPages <= maxPagesToShow) {
//     startPage = 1;
//     endPage = totalPages;
//   } else {
//     const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
//     if (currentPage <= halfMaxPagesToShow) {
//       startPage = 1;
//       endPage = maxPagesToShow;
//     } else if (currentPage + halfMaxPagesToShow >= totalPages) {
//       startPage = totalPages - maxPagesToShow + 1;
//       endPage = totalPages;
//     } else {
//       startPage = currentPage - halfMaxPagesToShow;
//       endPage = currentPage + halfMaxPagesToShow;
//     }
//   }

//   const pageNumbers = [];
//   for (let i = startPage; i <= endPage; i++) {
//     pageNumbers.push(i);
//   }

//   console.log("curent", currentPage);

//   return (
//     <nav>
//       <ul className="pagination justify-content-center">
//         <li className={`page-item ${currentPage === 1 && "disabled"}`}>
//           <button
//             className="page-link"
//             onClick={() => paginate(currentPage - 1)}
//             disabled={currentPage === 1}
//             aria-label="Previous Page"
//           >
//             Previous
//           </button>
//         </li>
//         {pageNumbers.map((number) => (
//           <li
//             key={number}
//             className={`page-item ${currentPage === number && "active"}`}
//           >
//             <button
//               onClick={() => paginate(number)}
//               className="page-link"
//               aria-label={`Go to page ${number}`}
//             >
//               {number}
//             </button>
//           </li>
//         ))}
//         <li className={`page-item ${currentPage === totalPages && "disabled"}`}>
//           <button
//             className="page-link"
//             onClick={() => paginate(currentPage + 1)}
//             disabled={currentPage === totalPages}
//             aria-label="Next Page"
//           >
//             Next
//           </button>
//         </li>
//       </ul>

//       {/* <input
//         type="number"
//         value={currentPage}
//         onChange={(e) => {
//           if (
//             Number(e.target.value) === 0 ||
//             Number(e.target.value) > totalPages
//           ) {
//             return;
//           }
//           paginate(Number(e.target.value));
//         }}
//         placeholder="Jump to page"
//         min="1"
//         max={totalPages}
//         aria-label="Jump to page number"
//       /> */}
//       {/* <div className="jump-to-page">
//         <input
//           type="number"
//           value={jumpPage}
//           onChange={(e) => setJumpPage(e.target.value)}
//           placeholder="Jump to page"
//           min="1"
//           max={totalPages}
//           aria-label="Jump to page number"
//         />
//         <button onClick={handleJumpPage} disabled={!jumpPage}>
//           Go
//         </button>
//       </div> */}
//     </nav>
//   );
// };

// export default Pagination;
