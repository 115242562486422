import React, { useState, useRef } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./addworkforce.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PhoneInputComponent from "../../../components/PhoneInputComp";
import { ages, countries, languages, passport } from "../../../utils/helpers";
import FormikSelect from "../../../components/FormikFields/FormikSelect";
import FormikInput from "../../../components/FormikFields/FormikInput";
import ImageUpload from "../../../components/ImageUpload";
import { addDataLakeWorkforce } from "../../../services/admin/manageworkforce/manageworkforceservices";
import SkillsSelectionAdd from "../../../components/FormikFields/FormikSkillsSelectionAdd";

const AddDataLakeWorkForce = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const initialSkillsList = location.state || [];
  console.log(initialSkillsList, "initialSkillsList");
  const phoneInputRef = useRef(null);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    profilePic: null,
    age: "",
    nationality: "",
    skills: [],
    countryCode: "",
    passport: "",
    language: "",
    city: "",
  };

  const handleSubmit = async (
    values,
    { resetForm, setFieldValue, setFieldError }
  ) => {
    console.log(values, "submit values");
    const data = {
      fullName: `${values.firstName} ${values.lastName}`,
      countryCode: values.countryCode,
      phone: values.phone,
      age: `${values.age}`,
      nationality: values.nationality,
      skill: values.skills.map((item) => ({
        _id: item._id,
        experience: item.experience,
        SkillName: item.skillName,
        subSkillId: item.subSkillId,
        SubSkillName: item.subSkillName,
        tertiarySkillName: item.tertiarySkillName,
      })),
      profile_pic: values.profilePic,
      is_passport: values.passport,
      language: values.language,
      city: values.city,
      created_by: "admin",
    };
    try {
      const response = await addDataLakeWorkforce(data);
      if (response.status === true) {
        toast.success(response?.message);
        navigate(-1);
      } else {
        toast.error(response?.response?.data?.message);
        const updatedskillval = values?.skill[0];
        setFieldValue("skill", updatedskillval);
        setPreviewImage(null);
        setFieldValue("phone", "");
        setFieldValue("skills", initialSkillsList);
        setFieldValue("countryCode", "");
        setFieldValue("profilePic", null);
        if (phoneInputRef.current) {
          phoneInputRef.current.focus(); // Focus the phone input field
        }
      }
    } catch (error) {
      // Reset form and explicitly clear each field
      setPreviewImage(null);
      setFieldValue("phone", "");
      setFieldValue("skills", initialSkillsList);
      setFieldValue("countryCode", "");
      setFieldValue("profilePic", null);
      if (phoneInputRef.current) {
        phoneInputRef.current.focus(); // Focus the phone input field
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "First name is required";
    }
    if (!values.lastName) {
      errors.lastName = "Last name is required";
    }
    if (!values.nationality) {
      errors.nationality = "Nationality is required";
    }
    if (!values.age) {
      errors.age = "Age is required";
    }
    if (!values.skills || values.skills.length === 0) {
      errors.skills = "Skills are required";
    }
    if (!values.passport) {
      errors.passport = "Passport is required";
    }
    if (!values.language) {
      errors.language = "Language is required";
    }
    if (!values.phone) {
      errors.phone = "Phone number is required";
    } else if (!/^\+[1-9]\d{1,14}$/.test(values.countryCode + values.phone)) {
      errors.phone = "Invalid phone number format";
    }
    return errors;
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setFieldValue("profilePic", file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onPhoneChange = (value, setFieldValue) => {
    setFieldValue("phone", value.number);
    setFieldValue("countryCode", value.countryCode);
  };
  return (
    <div className="add_user_page">
      <div className="card shadow p-5 m-5">
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
          enableReinitialize={true}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className="row">
                <ImageUpload
                  mainClass="col-md-4 form-group profile_pic_update"
                  previewConClass="img_block"
                  previewImage={previewImage}
                  placeholder="Profile Picture"
                  id="profilePic"
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                  inputClass="form-control-file d-none"
                  name="profilePic"
                  label="Upload Profile"
                  labelClass="global_admin_btn"
                />
                <div className="col-md-8 ps-4">
                  <h2 className="text-center pb-4">Add Workforce</h2>
                  <div className="form-group">
                    <div className="form-row row">
                      <FormikInput
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        name="firstName"
                        fieldClass="form-control"
                        mainClass="col-md-6"
                        errName="firstName"
                      />
                      <FormikInput
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        name="lastName"
                        fieldClass="form-control"
                        mainClass="col-md-6"
                        errName="lastName"
                      />
                    </div>
                  </div>
                  <div className="form-row row">
                    <div className="form-group col-md-6">
                      <PhoneInputComponent
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(value) =>
                          onPhoneChange(value, setFieldValue)
                        }
                        ref={phoneInputRef} // Attach ref to PhoneInputComponent
                        errName="phone"
                      />
                    </div>
                    <FormikSelect
                      as="select"
                      id="age"
                      name="age"
                      mainClass="form-group col-md-6"
                      errName="age"
                      options={ages}
                      placeholder="Select Age"
                    />
                  </div>
                  <div className="form-row row">
                    <FormikSelect
                      as="select"
                      id="nationality"
                      name="nationality"
                      mainClass="form-group col-md-6"
                      errName="nationality"
                      options={countries}
                      placeholder="Select Nationality"
                    />
                    <FormikSelect
                      as="select"
                      id="passport"
                      name="passport"
                      mainClass="form-group col-md-6"
                      errName="passport"
                      options={passport}
                      placeholder="Select Passport"
                    />
                  </div>
                  <div className="form-row row">
                    <FormikSelect
                      as="select"
                      id="language"
                      name="language"
                      mainClass="form-group col-md-6"
                      errName="language"
                      options={languages}
                      placeholder="Select Language"
                    />
                    <FormikInput
                      type="text"
                      id="city"
                      placeholder="City"
                      name="city"
                      fieldClass="form-control"
                      mainClass="col-md-6"
                      errName="city"
                    />
                  </div>

                  <SkillsSelectionAdd
                    errName="skills"
                    data={initialSkillsList}
                    onSelect={(value) => setFieldValue("skills", value)}
                  />
                  <div className="text-center">
                    <button
                      type="submit"
                      className="global_admin_btn me-2"
                      disabled={isSubmitting}
                    >
                      Add Workforce
                    </button>
                    <button
                      type="button"
                      className="global_admin_btn"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddDataLakeWorkForce;
