import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addskillset } from "../../services/admin/manageworkforce/manageworkforceservices";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { adminPathUrl } from "../../shared/pathUrl";
import PageTile from "../../components/pageTitle/pageTitle/PageTile";

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const categories = location.state;
  const [subFilterData, setSubFilterData] = useState([]);
  // Initial form values including tertiary skills
  const initialValues = {
    skillName: "",
    subSkills: [
      {
        subSkillName: "",
        tertiarySkills: [{ tertiarySkillName: "" }],
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    skillName: Yup.string().required("Category is required"),
    subSkills: Yup.array().of(
      Yup.object().shape({
        subSkillName: Yup.string().required("Sub category is required"),
        tertiarySkills: Yup.array().of(
          Yup.object().shape({
            tertiarySkillName: Yup.string().required(
              "Tertiary skill name is required"
            ),
          })
        ),
      })
    ),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const response = await addskillset(values);
      if (response?.status === true) {
        navigate(-1);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("Error adding category:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    navigate(adminPathUrl.category);
  };

  return (
    <div className="manage_blogs_page shadow card p-4 rounded">
      <PageTile heading1={"Add category"} backButton={true} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form-group">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="skillName">Category Name</label>
                <div
                  className="row combo_input"
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "7px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    className="col-4 px-0"
                    style={{ borderRight: "2px solid #ccc" }}
                  >
                    <Field
                      as="select"
                      name="skillName"
                      className="form-control mb-0 border-0 appearance-show mt-1"
                      value={values.skillName}
                      onChange={(e) => {
                        setFieldValue("skillName", e.target.value);
                        const a = categories?.filter(
                          (item) => item.newSkillName === e.target.value
                        );
                        setSubFilterData(a);
                      }}
                    >
                      <option value="">Select option</option>
                      {categories.map((item, index) => (
                        <option key={index} value={item.newSkillName}>
                          {item.newSkillName}
                        </option>
                      ))}
                    </Field>
                  </div>
                  <div className="col-8 px-0">
                    <Field
                      type="text"
                      name="skillName"
                      placeholder="Category Name"
                      className="form-control border-0 mb-0"
                      value={values.skillName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <ErrorMessage
                  name="skillName"
                  component="span"
                  className="text-danger"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <FieldArray name="subSkills">
                  {({ push, remove }) => (
                    <>
                      {values.subSkills.map((subSkill, index) => (
                        <div
                          key={index}
                          className="row combo_input align-items-center mb-4"
                        >
                          <div className="col-12">
                            <label htmlFor={`subSkills[${index}].subSkillName`}>
                              Sub Category Name
                            </label>
                            <div
                              className="row combo_input"
                              style={{
                                border: "2px solid #ccc",
                                borderRadius: "7px",
                                marginBottom: "15px",
                              }}
                            >
                              <div
                                className="col-4 px-0"
                                style={{ borderRight: "2px solid #ccc" }}
                              >
                                <Field
                                  as="select"
                                  name={`subSkills[${index}].subSkillName`}
                                  className="form-control mb-0 border-0 appearance-show mt-1"
                                  value={subSkill.subSkillName}
                                  onChange={handleChange}
                                >
                                  <option value="">Select option</option>
                                  {subFilterData &&
                                    subFilterData?.map((cur, idx) =>
                                      cur.subSkills?.map((item, innerIdx) => (
                                        <option
                                          key={`${idx}-${innerIdx}`}
                                          value={item.newSubSkillName}
                                        >
                                          {item.newSubSkillName}
                                        </option>
                                      ))
                                    )}
                                </Field>
                              </div>
                              <div className="col-8 px-0">
                                <Field
                                  type="text"
                                  name={`subSkills[${index}].subSkillName`}
                                  placeholder="Sub Category Name"
                                  className="form-control border-0 mb-0"
                                  value={subSkill.subSkillName}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <ErrorMessage
                              name={`subSkills[${index}].subSkillName`}
                              component="span"
                              className="text-danger"
                            />
                          </div>

                          {/* FieldArray for Tertiary Skills */}
                          <FieldArray
                            name={`subSkills[${index}].tertiarySkills`}
                          >
                            {({
                              push: pushTertiary,
                              remove: removeTertiary,
                            }) => (
                              <div>
                                <div className="d-flex align-items-center gap-3 mb-3">
                                  <label className="mb-0">
                                    Tertiary Skills
                                  </label>
                                  <Button
                                    type="button"
                                    className="global_admin_btn btn btn-weplaced py-2 px-4"
                                    onClick={() =>
                                      pushTertiary({ tertiarySkillName: "" })
                                    }
                                  >
                                    + Add Tertiary Skill
                                  </Button>
                                </div>
                                <div className="row tertiary_skill_focus">
                                  {subSkill.tertiarySkills.map(
                                    (tertiary, tIndex) => (
                                      <div key={tIndex} className="col-6 mb-3">
                                        <Field
                                          name={`subSkills[${index}].tertiarySkills[${tIndex}].tertiarySkillName`}
                                          className="form-control"
                                          placeholder="Tertiary Skill Name"
                                          value={tertiary.tertiarySkillName}
                                          onChange={handleChange}
                                        />
                                        <ErrorMessage
                                          name={`subSkills[${index}].tertiarySkills[${tIndex}].tertiarySkillName`}
                                          component="span"
                                          className="text-danger"
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </FieldArray>
                        </div>
                      ))}
                      <Button
                        type="button"
                        className="global_admin_btn  btn-weplaced addBtn py-2"
                        onClick={() =>
                          push({
                            subSkillName: "",
                            tertiarySkills: [{ tertiarySkillName: "" }],
                          })
                        }
                      >
                        + Add Sub Skill
                      </Button>
                    </>
                  )}
                </FieldArray>
              </div>
            </div>

            <div className="py-3">
              <Button
                type="submit"
                className="global_admin_btn btn btn-weplaced py-2 px-4"
                disabled={isSubmitting}
              >
                Save
              </Button>
              <button
                type="button"
                className="global_admin_btn btn py-2 px-4 btn-secondary ms-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddCategory;
