import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoSearchSharp } from "react-icons/io5";
import DataTableComponent from "../../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../../components/deleteModalAdmin";
import Loader from "../../../components/loader/loader";
import {
  debounce,
  downloadCsvPdfFile,
  getTertiarySkillList,
} from "../../../utils/methods";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ".././workforce.css";
import "../../index.css";
import usePagination from "../../../hooks/usePagination";
import { adminPathUrl } from "../../../shared/pathUrl";
import {
  getTertiarySkills,
  getworkforcelisting,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { getCategory } from "../../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import { fDateTime } from "../../../shared/helperfunction";
import { fileFormatDownload, typeForDownload } from "../../../shared/constant";
import CustomSearchDropdown from "../../../components/customSearchWithDropdown";

const RejectedProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillFilter, setSkillFilter] = useState(
    location?.state?.skillfiltervalue ?? ""
  );
  const [experienceFilter, setExperienceFilter] = useState(
    location?.state?.experiencefiltervalue ?? ""
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [listData, setListData] = useState([]);
  const [skillsetData, setSkillsetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skillList, setSkillList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { pagination, setPagination } = usePagination();
  const userColumns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },

    {
      name: "Phone",
      selector: (row) => {
        const countryCode = row.countryCode;
        const phoneNumber = row.phone;
        return `${countryCode} ${phoneNumber}`;
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Nationality",
      selector: (row) => row?.nationality,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.city,
      sortable: true,
    },
    {
      name: "Age",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "Skills",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>
              {item?.SubSkillName
                ? item?.SubSkillName
                : item?.tertiarySkillName}
            </p>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Experience",
      selector: (row) => (
        <div className="experience_col">
          {row.skills.map((item, index) => (
            <p key={index}>{item.experience}</p>
          ))}
        </div>
      ),
      sortable: true,
      width: "110px",
    },
    {
      name: "Account Status",
      selector: (row) => (
        <span
          style={{
            color: row.account_status === "Active" ? "green" : "red",
          }}
        >
          {row.account_status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => fDateTime(row.createdAt, "dd-MMM-yyyy"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Time",
      selector: (row) => fDateTime(row.createdAt, "p"),
      sortable: true,
      width: "110px",
    },
    // {
    //   name: "Verification Status",
    //   selector: (row) => (
    //     <span
    //       style={{
    //         color:
    //           row.is_admin_verified === "Verified"
    //             ? "green"
    //             : row.is_admin_verified === "Unverified"
    //             ? "darkgoldenrod"
    //             : row.is_admin_verified === "Rejected"
    //             ? "red"
    //             : "black",
    //       }}
    //     >
    //       {row.is_admin_verified}
    //     </span>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },
    {
      name: "Passport",
      selector: (row) => row?.is_passport,
    },
  ];
  const fetchWorkforceData = async (
    page,
    searchTerm,
    skillFilter,
    experienceFilter
  ) => {
    setLoading(true);
    try {
      const response = await getworkforcelisting({
        body: null,
        type: "rejected_profiles",
        page: page,
        limit: "30",
        search: searchTerm,
        skills_Filter: skillFilter,
        experience_Filter: experienceFilter,
      });
      if (response?.data && response?.status) {
        // setListData(response.data.data);
        setFilteredData(response?.data?.data);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: response?.data?.totalPages,
            totalCount: response?.data?.totalUsers,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false);
    }
  };
  // create a debounced version for the delete user
  const debouncedSearchForRejectedData = useCallback(
    debounce(fetchWorkforceData, 800),
    []
  );
  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        const data = await getTertiarySkills({ body: null, type: "gulf_kaam" });
        setSkillsetData(data);
      } catch (error) {
        console.error("Error fetching skillset:", error);
      }
    };
    fetchSkillset();
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => setSkillList(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (searchTerm) {
      debouncedSearchForRejectedData(
        pagination?.currentPage,
        searchTerm,
        skillFilter,
        experienceFilter
      );
    } else {
      fetchWorkforceData(
        pagination?.currentPage,
        searchTerm,
        skillFilter,
        experienceFilter
      );
    }
  }, [pagination?.currentPage, searchTerm, skillFilter, experienceFilter]);

  // useEffect(() => {
  //   let filtered = listData;

  //   if (skillFilter && skillFilter !== "") {
  //     filtered = filtered.filter((item) =>
  //       item.skills.some((skill) => skill.SubSkillName === skillFilter)
  //     );
  //   }
  //   if (experienceFilter) {
  //     filtered = filtered.filter((item) => {
  //       const experienceYears = item.skills.reduce(
  //         (acc, skill) => acc + getExperienceYears(skill),
  //         0
  //       );
  //       switch (experienceFilter) {
  //         case "0-1":
  //           return experienceYears >= 0 && experienceYears <= 1;
  //         case "1-2":
  //           return experienceYears > 1 && experienceYears <= 2;
  //         case "2-4":
  //           return experienceYears > 2 && experienceYears <= 4;
  //         case "4+":
  //           return experienceYears > 4;
  //         default:
  //           return true;
  //       }
  //     });
  //   }
  //   setFilteredData(filtered);
  // }, [skillFilter, experienceFilter, listData]);

  const handleRowClicked = (row, event) => {
    const targetColumn = event.target.closest(".rdt_TableCell");
    if (!targetColumn) return;
    const columnIndex = Array.from(targetColumn.parentNode.children).indexOf(
      targetColumn
    );
    if (columnIndex === 1) return;
    navigate(adminPathUrl.gulfWorkforceDetail, {
      state: {
        id: row._id,
        currentPage: currentPage,
        isHighlightUser: "rejectuser",
        skillfiltervalue: skillFilter,
        experiencefiltervalue: experienceFilter,
      },
    });
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleSkillFilterChange = (event) => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setSkillFilter(event ? event : "");
  };

  const handleExperienceFilterChange = (event) => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setExperienceFilter(event.target.value);
  };

  const getExperienceYears = (skill) => {
    if (typeof skill.experience === "string") {
      const match = skill.experience.match(/(\d+) years/);
      return match ? parseInt(match[1], 10) : 0;
    }
    return 0;
  };

  useEffect(() => {
    const currentState = location.state || {};
    const updatedState = {
      ...currentState,
      experiencefiltervalue: "",
      skillfiltervalue: "",
    };

    navigate(location.pathname, { state: updatedState, replace: true });
  }, [skillFilter, experienceFilter]);

  if (loading) {
    return <Loader />;
  }

  const subSkillNames = getTertiarySkillList(skillsetData.data);

  const pdfHeaders = [
    "Name",
    "Phone",
    "Age",
    "Skills with Experience",
    "Account Status",
    "Date & Time",
  ];

  return (
    <div className="user_page">
      <ToastContainer />
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="mb-0">
          <input
            className="searchbar"
            type="search"
            placeholder="search...."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>
        <div className="filters_group text-end mt-2 col-md-6 gap-2">
          <FormControl fullWidth className="mx-2 d-none">
            <InputLabel id="skill-filter-label">Skill</InputLabel>
            <Select
              labelId="skill-filter-label"
              value={skillFilter}
              onChange={handleSkillFilterChange}
              label="Skill"
            >
              <MenuItem value="">
                <em>All Skills</em>
              </MenuItem>
              {subSkillNames?.sort()?.map((skill, index) => (
                <MenuItem key={index} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={{ width: "60%" }}>
            <CustomSearchDropdown
              data={subSkillNames}
              onChange={handleSkillFilterChange}
              value={skillFilter}
              label="Skill"
            />
          </div>
          <FormControl style={{ width: "30%" }} className="mx-2">
            <InputLabel id="experience-filter-label">Experience</InputLabel>
            <Select
              labelId="experience-filter-label"
              value={experienceFilter}
              onChange={handleExperienceFilterChange}
              label="Experience"
            >
              <MenuItem value="">
                <em>All Experiences</em>
              </MenuItem>
              <MenuItem value="1">0-1 years</MenuItem>
              <MenuItem value="2">1-2 years</MenuItem>
              <MenuItem value="4">2-4 years</MenuItem>
              <MenuItem value="5">4+ years</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="datatable-section">
        <DataTableComponent
          onCurrentPageChange={(page) => setCurrentPage(page)}
          toRedirectPage={
            pagination.currentPage && (skillFilter || experienceFilter)
              ? pagination.currentPage
              : location?.state?.currentPage
          }
          title="Rejected Profiles"
          data={filteredData}
          columns={userColumns}
          selectedRows={selectedRows}
          clickedrow={handleRowClicked}
          checkboxchange={handleCheckboxChange}
          responsive={true}
          downloadBtns={true}
          downloadCSV={() =>
            downloadCsvPdfFile(
              typeForDownload.rejectedProfile,
              fileFormatDownload.csv,
              "Rejected Profiles"
            )
          }
          downloadPDF={() =>
            downloadCsvPdfFile(
              typeForDownload.rejectedProfile,
              fileFormatDownload.pdf,
              "Rejected Profiles"
            )
          }
          pdfHeaders={pdfHeaders}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Worker"}
      />
    </div>
  );
};

export default RejectedProfile;
