import React, { useState, useEffect, useCallback } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import DataTableComponent from "../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../components/deleteModalAdmin";
import { adminPathUrl } from "../../shared/pathUrl";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  getWorkforceEnquiryListing,
  getWorkforceEnquiryById,
} from "../../services/admin/manageworkforce/manageWorkEnquiryServices";
import { getTertiarySkills } from "../../services/admin/manageworkforce/manageworkforceservices";
import Loader from "../../components/loader/loader";
import {
  debounce,
  downloadCSV,
  downloadPDF,
  getTertiarySkillList,
  textFormatter,
} from "../../utils/methods";
import { STATUS_CLASES } from "../../utils/helpers";
import "jspdf-autotable";
import usePagination from "../../hooks/usePagination";
import CustomSearchDropdown from "../../components/customSearchWithDropdown";

export default function ManageManpowerEnquiry() {
  const location = useLocation();
  const { pagination, setPagination } = usePagination();
  const { currentPage, limit } = pagination;
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillsetData, setSkillsetData] = useState([]);
  const [skillFilter, setSkillFilter] = useState(
    location?.state?.skillfiltervalue ?? ""
  );
  const [subSkillFilter, setSubSkillFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [termFilter, setTermFilter] = useState(
    location?.state?.termfiltervalue ?? ""
  );
  const [countryFilter, setCountryFilter] = useState(
    location?.state?.countryvalue ?? ""
  );
  const [statusFilter, setStatusFilter] = useState(
    location?.state?.statusfiltervalue ?? ""
  );
  const [workforceEnquiries, setWorkforceEnquiries] = useState([]);
  const [loading, setLoading] = useState(true);

  const userColumns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "220px",
    },
    {
      name: "Contact number",
      selector: (row) => row.contactNumber,
      sortable: true,
      width: "200px",
    },
    {
      name: "Worker Category",
      cell: (row) => (
        <div>
          {row.worker_category.map((category, index) => (
            <div key={index}>{category}</div>
          ))}
        </div>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      sortable: true,
      width: "250px",
    },
    {
      name: "Term",
      selector: (row) =>
        row.tenure === "" ? "n/a" : textFormatter(row.tenure),
      sortable: true,
    },
    {
      name: "Date & Time",
      selector: (row) => formatDateTime(row.createdAt),
      sortable: true,
      width: "250px",
    },
    {
      name: "Status",
      selector: (row) => (
        <span className={STATUS_CLASES[row.status.toLowerCase()]}>
          {row.status}
        </span>
      ),
      sortable: true,
    },
  ];

  const formatDateTime = (date) => {
    if (!date) return "";
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const fetchWorkforceEnquiries = async (
    currentPage,
    limit,
    searchQuery,
    skillFilter,
    countryFilter,
    statusFilter,
    termFilter
  ) => {
    setLoading(true);
    try {
      const response = await getWorkforceEnquiryListing({
        page: currentPage,
        limit: limit,
        search: searchQuery,
        skill_filter: skillFilter,
        country_Filter: countryFilter,
        status_Filter: statusFilter,
        term_Filter: termFilter,
        type: "admin",
      });
      if (response?.data && response?.status) {
        setWorkforceEnquiries(response?.data?.data);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: response?.data?.totalPages,
            totalCount: response?.data?.totalCompanies,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSkillset = async () => {
    try {
      const data = await getTertiarySkills({ body: null, type: "we_placed" });
      setSkillsetData(data);
    } catch (error) {
      console.error("Error fetching skillset:", error);
    }
  };

  const debouncedSearchHandler = useCallback(
    debounce(fetchWorkforceEnquiries, 800),
    []
  );

  useEffect(() => {
    fetchSkillset();
  }, []);
  useEffect(() => {
    if (searchQuery == "") {
      fetchWorkforceEnquiries(
        currentPage,
        limit,
        searchQuery,
        skillFilter,
        countryFilter,
        statusFilter,
        termFilter
      );
    } else {
      debouncedSearchHandler(
        currentPage,
        limit,
        searchQuery,
        skillFilter,
        countryFilter,
        statusFilter,
        termFilter
      );
    }
  }, [
    currentPage,
    searchQuery,
    skillFilter,
    countryFilter,
    statusFilter,
    termFilter,
  ]);

  const handleRowClicked = async (row, event) => {
    const targetColumn = event.target.closest(".rdt_TableCell");
    if (!targetColumn) return;
    const columnIndex = Array.from(targetColumn.parentNode.children).indexOf(
      targetColumn
    );
    if (columnIndex === 2) return;
    try {
      const enquiryData = await getWorkforceEnquiryById(row._id);
      if (enquiryData && enquiryData.data) {
        navigate(`${adminPathUrl.manageManpowerDetail}/${row._id}`, {
          state: {
            rowData: enquiryData.data.data,
            currentPage: currentPage,
            skillfiltervalue: skillFilter,
            statusfiltervalue: statusFilter,
            termfiltervalue: termFilter,
            countryvalue: countryFilter,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching enquiry data:", error);
    }
  };

  const handleSkillFilterChange = (event) => {
    setSkillFilter(event ? event : "");
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setSubSkillFilter("");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTermFilterChange = (event) => {
    setTermFilter(event.target.value);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleCountryFilterChange = (event) => {
    setCountryFilter(event.target.value);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };
  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const getUniqueCountries = () => {
    const countries = new Set(
      workforceEnquiries.map((enquiry) => enquiry.country)
    );
    return [...countries];
  };
  const filteredData = workforceEnquiries.filter((row) => {
    const fullName = row.fullName || "";
    const email = row.email || "";
    const contactNumber = row.contactNumber || "";
    const companyName = row.companyName || "";
    const workerCategory = row.worker_category || [];
    const tenure = row.tenure || "";
    const country = row.country || "";

    // Check if the row's worker_category includes the skillFilter
    const matchesSkillFilter = skillFilter
      ? workerCategory.some((category) =>
          category?.toLowerCase()?.includes(skillFilter.toLowerCase())
        )
      : true;

    // Check if the row's worker_category includes the subSkillFilter
    const matchesSubSkillFilter = subSkillFilter
      ? workerCategory.some((category) =>
          category.toLowerCase().includes(subSkillFilter.toLowerCase())
        )
      : true;

    // Check other filters and search query
    const matchesTermFilter = termFilter ? tenure === termFilter : true;
    const matchesSearchQuery = searchQuery
      ? fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        contactNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        companyName.toLowerCase().includes(searchQuery.toLowerCase())
      : true;
    const matchesCountryFilter = countryFilter
      ? country === countryFilter
      : true;
    const matchesStatusFilter = statusFilter
      ? row.status.toLowerCase() === statusFilter.toLowerCase()
      : true;

    return (
      matchesSkillFilter &&
      matchesSubSkillFilter &&
      matchesTermFilter &&
      matchesSearchQuery &&
      matchesCountryFilter &&
      matchesStatusFilter
    );
  });
  console.log(location, "loll");
  useEffect(() => {
    const currentState = location.state || {};
    const updatedState = {
      ...currentState,
      countryvalue: "",
      termfiltervalue: "",
      statusfiltervalue: "",
      skillfiltervalue: "",
    };

    navigate(location.pathname, { state: updatedState, replace: true });
  }, [skillFilter, countryFilter, statusFilter, termFilter]);

  if (loading) {
    return <Loader />;
  }

  const subSkillNames = getTertiarySkillList(skillsetData.data);

  const pdfHeaders = [
    "Name",
    "Email",
    "Contact Number",
    "Worker Category",
    "Country",
    "Term",
    "Date & Time",
    "Status",
  ];

  const pdfData = filteredData.map((row) => [
    row.fullName,
    row.email,
    row.contactNumber,
    row.worker_category.join(", "),
    row.country,
    row.tenure === "" ? "n/a" : textFormatter(row.tenure),
    formatDateTime(row.createdAt),
    row.status,
  ]);

  const pdfFileName = "Manpower Enquiry.pdf";
  const csvFileName = "Manpower Enquiry.csv";

  const csvData = filteredData.map((row) => ({
    Name: row.fullName,
    Email: row.email,
    ContactNumber: row.contactNumber,
    WorkerCategory: row.worker_category.join(", "),
    Country: row.country,
    Term: row.tenure === "" ? "n/a" : textFormatter(row.tenure),
    DateTime: formatDateTime(row.createdAt),
    Status: row.status,
  }));
  return (
    <div className="user_page">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="mb-0">
          <input
            className="searchbar"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>
        <div className="filters_group text-end mt-2 col-md-8">
          <div style={{ width: "40%" }}>
            <CustomSearchDropdown
              data={subSkillNames?.sort()}
              onChange={handleSkillFilterChange}
              value={skillFilter}
              label="Skill"
            />
          </div>
          <FormControl style={{ width: "20%" }}>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              labelId="status-filter-label"
              value={statusFilter}
              onChange={handleStatusFilterChange}
              label="Status"
            >
              <MenuItem value="">
                <em>All Statuses</em>
              </MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={{ width: "20%" }}>
            <InputLabel id="term-filter-label">Term</InputLabel>
            <Select
              labelId="term-filter-label"
              value={termFilter}
              onChange={handleTermFilterChange}
              label="Term"
            >
              <MenuItem value="">
                <em>All Terms</em>
              </MenuItem>
              <MenuItem value="short_term">Short Term</MenuItem>
              <MenuItem value="long_term">Long Term</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className="d-none">
            <InputLabel id="skill-filter-label">Skill</InputLabel>
            <Select
              labelId="skill-filter-label"
              value={skillFilter}
              onChange={handleSkillFilterChange}
              label="Skill"
            >
              <MenuItem value="">
                <em>All Skills</em>
              </MenuItem>
              {subSkillNames?.map((skill, index) => (
                <MenuItem key={index} value={skill}>
                  {skill}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ width: "20%" }}>
            <InputLabel id="country-filter-label">Country</InputLabel>
            <Select
              labelId="country-filter-label"
              value={countryFilter}
              onChange={handleCountryFilterChange}
              label="Country"
            >
              <MenuItem value="">
                <em>All Countries</em>
              </MenuItem>
              {getUniqueCountries().map((country, index) => (
                <MenuItem key={index} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="datatable-section">
        <DataTableComponent
          toRedirectPage={
            currentPage &&
            (skillFilter || statusFilter || termFilter || countryFilter)
              ? currentPage
              : location?.state?.currentPage
          }
          title="Manage Manpower Enquiry"
          data={workforceEnquiries}
          columns={userColumns}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          clickedrow={handleRowClicked}
          responsive={true}
          downloadBtns={true}
          downloadCSV={() => downloadCSV(csvData, csvFileName)}
          downloadPDF={() => downloadPDF(pdfHeaders, pdfData, pdfFileName)}
          pdfHeaders={pdfHeaders}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure you want to delete this enquiry?"}
        onConfirm={() => {
          // Handle delete action
          setDeleteModal(false);
        }}
      />
    </div>
  );
}
