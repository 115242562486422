import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import { FaUpload } from "react-icons/fa";
import PageTile from "../../../components/pageTitle/pageTitle/PageTile";
import CommonEditor from "../../../components/commonEditor/commonEditor";
import {
  addAboutUsService,
  getAboutUsService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
import ImageResolutionWarningModal from "../../../components/ImageCompressModal";
import { resizeImage } from "../../../utils/methods";
import Description from "../../../components/Description";
import MaxLimit from "../../../components/MaxLimit";

// Define maximum lengths for each content type
const MAX_LENGTH_ABOUT_US_CONTENT = 488;
const MAX_LENGTH_WHAT_WE_DO_CONTENT = 1185; // Example value, adjust as needed
const MAX_LENGTH_CONTENT = {
  content_1: 592,
  content_2: 524,
  content_3: 574,
  content_4: 428,
};
const MAX_LENGTHS = {
  what_sets_up_apart_title: 20,
  what_we_do_title: 15,
  about_us_title: 25,
};
function ManageAboutUs() {
  const [cmsContents, setCMSContents] = useState(null);
  const fileInputRef = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [tempFile, setTempFile] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB
  const maxWidth = 950;
  const maxHeight = 600;

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const response = await getAboutUsService();
        if (response.status) {
          const aboutUsData = response.data[0];
          setCMSContents(aboutUsData);
          if (aboutUsData.about_us_pic) {
            setImageUrl(aboutUsData.about_us_pic);
          }
        } else {
          console.error("Failed to fetch About Us content:", response.message);
        }
      } catch (error) {
        console.error("Error fetching About Us content:", error);
      }
    };
    fetchAboutUs();
  }, []);

  const handleEditorChange = (value, section) => {
    let maxLength;

    switch (section) {
      case "about_us_content":
        maxLength = MAX_LENGTH_ABOUT_US_CONTENT;
        break;
      case "what_we_do_content":
        maxLength = MAX_LENGTH_WHAT_WE_DO_CONTENT;
        break;
      case "content_1":
      case "content_2":
      case "content_3":
      case "content_4":
        maxLength = MAX_LENGTH_CONTENT;
        break;
      default:
        maxLength = 0; // Default to 0 if not found
    }

    if (value?.length <= maxLength) {
      setCMSContents((prevState) => ({
        ...prevState,
        [section]: value,
      }));
    }
  };

  const handleImageChange = async (e, setFieldValue) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast.error(
        "File size exceeds 20 MB limit. Please select a smaller file."
      );
      return;
    }

    const img = new Image();
    const reader = new FileReader();

    reader.onload = () => {
      img.src = reader.result;
    };

    img.onload = async () => {
      const { width, height } = img;

      if (width > maxWidth || height > maxHeight) {
        setTempFile(file);
        setShowModal(true);
      } else {
        setImage(file);
        setImageUrl(URL.createObjectURL(file));
        setFieldValue("about_us_pic", file);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleCompressImage = async (setFieldValue) => {
    if (!tempFile) return;

    const resizedFile = await resizeImage(tempFile, maxWidth, maxHeight);

    setImage(resizedFile);
    setImageUrl(URL.createObjectURL(resizedFile));
    setShowModal(false);

    // Update Formik's field value with the compressed image
    setFieldValue("about_us_pic", resizedFile);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTempFile(null);
    fileInputRef.current.value = "";
  };

  const handleSubmit = async (values, { setSubmitting }, section) => {
    try {
      const formData = new FormData();
      formData.append(`${section}_title`, values[`${section}_title`]);
      formData.append(`${section}_content`, values[`${section}_content`]);

      // Check if section is about_us and image is selected
      if (section === "about_us" && image) {
        formData.append("about_us_pic", image);
      }

      // Append content fields for "What Sets Us Apart" section
      if (section === "what_sets_up_apart") {
        formData.append("content_1", values["content_1"] || "");
        formData.append("content_2", values["content_2"] || "");
        formData.append("content_3", values["content_3"] || "");
        formData.append("content_4", values["content_4"] || "");
      }

      const response = await addAboutUsService(cmsContents._id, formData);

      if (response.status === true) {
        toast.success(`${section} content updated successfully!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Update state with the latest content after successful update
        const updatedContent = response.data;
        setCMSContents(updatedContent);
      } else {
        toast.error(`Failed to update ${section} content: ${response.message}`);
      }
    } catch (error) {
      console.error(`Error updating ${section} content:`, error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      {cmsContents && (
        <div>
          {/* About Us Section */}
          <div className="manage_blogs_page shadow card p-4 rounded">
            <PageTile heading1={"About Us"} />
            <Formik
              enableReinitialize
              initialValues={{
                about_us_title: cmsContents.about_us_title || "",
                about_us_content: cmsContents.about_us_content || "",
              }}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, "about_us")
              }
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="about_us_title">Title</label>
                    <MaxLimit
                      current={values.about_us_title.length}
                      max={MAX_LENGTHS.about_us_title}
                    />
                    <Field
                      type="text"
                      name="about_us_title"
                      maxLength={MAX_LENGTHS.about_us_title}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="about_us_pic">Image Upload</label>
                    <div className="input-group mb-3">
                      <input
                        type="file"
                        name="about_us_pic"
                        id="about_us_pic"
                        onChange={(e) => handleImageChange(e, setFieldValue)}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => fileInputRef.current.click()}
                        >
                          <FaUpload /> Upload Image
                        </button>
                      </div>
                    </div>
                    <Description
                      message={`Please upload an image with a resolution of (${maxWidth} × ${maxHeight}) pixels.`}
                    />
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="Selected"
                        style={{ maxWidth: "100%", marginTop: "10px" }}
                      />
                    )}
                  </div>

                  <div className="form-group">
                    <CommonEditor
                      value={cmsContents.about_us_content || ""}
                      onChange={(value) =>
                        handleEditorChange(value, "about_us_content")
                      }
                      maxLength={MAX_LENGTH_ABOUT_US_CONTENT}
                      label="Content"
                    />
                  </div>
                  <div className="py-3">
                    <button type="submit" className="global_admin_btn">
                      Save
                    </button>
                  </div>
                  <ImageResolutionWarningModal
                    show={showModal}
                    onHide={handleCloseModal}
                    onCompress={() => handleCompressImage(setFieldValue)}
                    resolution={`${maxWidth}x${maxHeight}`}
                  />
                </Form>
              )}
            </Formik>
          </div>

          {/* What We Do Section */}
          <div className="manage_blogs_page shadow card p-4 rounded">
            <PageTile heading1={"What We Do"} />
            <Formik
              enableReinitialize
              initialValues={{
                what_we_do_title: cmsContents.what_we_do_title || "",
                what_we_do_content: cmsContents.what_we_do_content || "",
              }}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, "what_we_do")
              }
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="what_we_do_title">Title</label>
                    <MaxLimit
                      current={values.what_we_do_title.length}
                      max={MAX_LENGTHS.what_we_do_title}
                    />
                    <Field
                      type="text"
                      name="what_we_do_title"
                      maxLength={MAX_LENGTHS.what_we_do_title}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <CommonEditor
                      value={cmsContents.what_we_do_content || ""}
                      onChange={(value) =>
                        handleEditorChange(value, "what_we_do_content")
                      }
                      maxLength={MAX_LENGTH_WHAT_WE_DO_CONTENT}
                      label="Content"
                    />
                  </div>
                  <div className="py-3">
                    <button type="submit" className="global_admin_btn">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          {/* What Sets Us Apart Section */}
          <div className="manage_blogs_page shadow card p-4 rounded">
            <PageTile heading1={"What Sets Us Apart"} />
            <Formik
              enableReinitialize
              initialValues={{
                what_sets_up_apart_title:
                  cmsContents.what_sets_up_apart_title || "",
                content_1: cmsContents.content_1 || "",
                content_2: cmsContents.content_2 || "",
                content_3: cmsContents.content_3 || "",
                content_4: cmsContents.content_4 || "",
              }}
              onSubmit={(values, actions) =>
                handleSubmit(values, actions, "what_sets_up_apart")
              }
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="what_sets_up_apart_title">Title</label>
                    <MaxLimit
                      current={values.what_sets_up_apart_title.length}
                      max={MAX_LENGTHS.what_sets_up_apart_title}
                    />
                    <Field
                      type="text"
                      name="what_sets_up_apart_title"
                      maxLength={MAX_LENGTHS.what_sets_up_apart_title}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <CommonEditor
                      value={cmsContents.content_1 || ""}
                      onChange={(value) =>
                        handleEditorChange(value, "content_1")
                      }
                      maxLength={MAX_LENGTH_CONTENT.content_1}
                      label="Content 1"
                    />
                    <CommonEditor
                      value={cmsContents.content_2 || ""}
                      onChange={(value) =>
                        handleEditorChange(value, "content_2")
                      }
                      maxLength={MAX_LENGTH_CONTENT.content_2}
                      label="Content 2"
                    />
                    <CommonEditor
                      value={cmsContents.content_3 || ""}
                      onChange={(value) =>
                        handleEditorChange(value, "content_3")
                      }
                      maxLength={MAX_LENGTH_CONTENT.content_3}
                      label="Content 3"
                    />
                    <CommonEditor
                      value={cmsContents.content_4 || ""}
                      onChange={(value) =>
                        handleEditorChange(value, "content_4")
                      }
                      maxLength={MAX_LENGTH_CONTENT.content_4}
                      label="Content 4"
                    />
                  </div>
                  <div className="py-3">
                    <button type="submit" className="global_admin_btn">
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageAboutUs;
