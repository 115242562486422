const useMaxLimit = (setContent) => {
  const MAX_LENGTH = 100;
  const handleEditorChange = (value) => {
    // if (value?.length <= MAX_LENGTH) {
    setContent(value);
    // }
  };
  return { MAX_LENGTH, handleEditorChange };
};

export default useMaxLimit;
