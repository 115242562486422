import React, { useState, useEffect, useCallback } from "react";
import { FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPathUrl } from "../../shared/pathUrl";
import { IoSearchSharp } from "react-icons/io5";
import {
  getTertiarySkills,
  getworkforcelisting,
} from "../../services/admin/manageworkforce/manageworkforceservices";
import DataTableComponent from "../../components/dataTable/dataTable";
import DeleteModalAdmin from "../../components/deleteModalAdmin";
import Loader from "../../components/loader/loader";
import { getCategory } from "../../services/web/manpowerEnquiry/manpowerEnquiryservices";
import "./workforce.css";
import "../../index.css";
import {
  debounce,
  downloadCsvPdfFile,
  getTertiarySkillList,
} from "../../utils/methods";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import usePagination from "../../hooks/usePagination";
import useSearch from "../../hooks/useSearch";
import { fDateTime } from "../../shared/helperfunction";
import { fileFormatDownload, typeForDownload } from "../../shared/constant";
import CustomSearchDropdown from "../../components/customSearchWithDropdown";

const DataLakeWorkForce = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [skillFilter, setSkillFilter] = useState(
    location?.state?.skillfiltervalue ?? ""
  );
  const [experienceFilter, setExperienceFilter] = useState(
    location?.state?.experiencefiltervalue ?? ""
  );

  useEffect(() => {
    const currentState = location.state || {};
    const updatedState = {
      ...currentState,
      experiencefiltervalue: "",
      skillfiltervalue: "",
    };

    navigate(location.pathname, { state: updatedState, replace: true });
  }, [skillFilter, experienceFilter]);

  const [listData, setListData] = useState([]);
  const [skillsetData, setSkillsetData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skillList, setSkillList] = useState([]);
  const [verificationFilter, setVerificationFilter] = useState("");
  const [userDeleteCall, setUserDeleteCall] = useState({
    deleteUser: false,
  });
  const { search, setSearch } = useSearch("");

  const [selectedId, setSelectedId] = useState("");
  useEffect(() => {
    const findIndex = filteredData.findIndex(
      (curElm) => curElm?._id == location?.state?.location_id
    );
    setSelectedId(location?.state?.saveFlag ? findIndex + 1 : findIndex);
  }, [filteredData]);
  const userColumns = [
    {
      name: "Name",
      selector: (row) => row?.fullName,
      sortable: true,
      width: "200px",
    },

    {
      name: "Phone",
      selector: (row) => {
        const countryCode = row?.countryCode;
        const phoneNumber = row?.phone;
        return `${countryCode} ${phoneNumber}`;
      },
      sortable: true,
      width: "180px",
    },
    {
      name: "Nationality",
      selector: (row) => row?.nationality,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row?.city,
      sortable: true,
      width: "150px",
    },
    {
      name: "Age",
      selector: (row) => row?.age,
      sortable: true,
      width: "90px",
    },
    {
      name: "Skills",
      selector: (row) => (
        <div
          onClick={() =>
            navigate(adminPathUrl?.dataLakeWorkforceDetail, {
              state: {
                id: row?._id,
                skills: row?.skills,
                currentPage: currentPage,
              },
            })
          }
          className="experience_col"
        >
          {row?.skills.map((item, index) => (
            <p key={index}>
              {item?.SubSkillName
                ? item?.SubSkillName
                : item?.tertiarySkillName}
            </p>
          ))}
        </div>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: "Experience",
      selector: (row) => (
        <div className="">
          {row?.skills.map((item, index) => {
            const experience = item?.experience || "";
            let matches = experience.match(/(\d+)/);

            return (
              <>{matches && matches[0] && <p key={index}>{matches[0]}</p>}</>
            );
          })}
        </div>
      ),
      sortable: true,
      width: "150px",
    },
    {
      name: "Account Status",
      selector: (row) => (
        <span
          style={{
            color: row?.account_status === "Active" ? "green" : "red",
          }}
        >
          {row?.account_status}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => fDateTime(row?.createdAt, "dd-MMM-yyyy"),
      sortable: true,
      width: "150px",
    },
    {
      name: "Time",
      selector: (row) => fDateTime(row?.createdAt, "p"),
      sortable: true,
      width: "110px",
    },
    // {
    //   name: "Verification Status",
    //   selector: (row) => (
    //     <span
    //       style={{
    //         color:
    //           row?.is_admin_verified === "Verified"
    //             ? "green"
    //             : row?.is_admin_verified === "Unverified"
    //             ? "darkgoldenrod"
    //             : row?.is_admin_verified === "Rejected"
    //             ? "red"
    //             : "black",
    //       }}
    //     >
    //       {row?.is_admin_verified}
    //     </span>
    //   ),
    //   sortable: true,
    //   width: "150px",
    // },

    {
      name: "Passport",
      selector: (row) => row?.is_passport,
    },
  ];
  useEffect(() => {
    const fetchSkillset = async () => {
      try {
        const data = await getTertiarySkills({ body: null, type: "data_lake" });
        setSkillsetData(data);
      } catch (error) {
        console.error("Error fetching skillset:", error);
      }
    };
    fetchSkillset();
  }, []);

  useEffect(() => {
    getCategory()
      .then((res) => setSkillList(res.data))
      .catch((err) => console.log(err));
  }, []);

  // const onSearchHandler = async (search = "") => {
  //   try {
  //     const response = await getworkforcelisting({
  //       body: null,
  //       type: "data_lake",
  //       search: search,
  //     });
  //     if (response?.data && response?.status) {
  //       // const withoutVerifiedUsers = response.data.data.filter(
  //       //   (item) => item.is_admin_verified !== "Verified"
  //       // );
  //       // setListData(withoutVerifiedUsers);
  //       setFilteredData(response?.data?.data);
  //       setPagination((prev) => {
  //         return {
  //           ...prev,
  //           totalPages: response?.data?.totalPages,
  //           totalCount: response?.data?.totalUsers,
  //         };
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching workforce data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchWorkforceData = async (page, limit, search = "") => {
    if (search === "") {
      setLoading(true);
    }
    try {
      const response = await getworkforcelisting({
        body: null,
        type: "data_lake",
        page: page,
        limit: limit,
        search: search,
        skills_Filter: skillFilter,
        experience_Filter: experienceFilter,
      });
      if (response?.data && response?.status) {
        setFilteredData(response?.data?.data);
        setPagination((prev) => {
          return {
            ...prev,
            totalPages: response?.data?.totalPages,
            totalCount: response?.data?.totalUsers,
          };
        });
      }
    } catch (error) {
      console.error("Error fetching workforce data:", error);
    } finally {
      setLoading(false);
    }
  };
  const debouncedSearchHandler = useCallback(
    debounce(fetchWorkforceData, 300),
    []
  );
  const { pagination, setPagination } = usePagination();
  const { currentPage, limit } = pagination;

  // useEffect(() => {
  //   fetchWorkforceData(currentPage, limit, search);
  // }, [currentPage, skillFilter, experienceFilter]);
  useEffect(() => {
    if (search === "") {
      fetchWorkforceData(currentPage, limit, search);
    } else {
      debouncedSearchHandler(currentPage, limit, search);
    }
  }, [search, currentPage, skillFilter, experienceFilter]);
  // const handleRowClicked = (row) => {
  //   setSelectedId(row?._id);
  //   navigate(adminPathUrl.dataLakeWorkforceDetail, {
  //     state: {
  //       id: row._id,
  //       skills: row.skills,
  //       currentPage: currentPage,
  //     },
  //   });
  // };

  const handleRowClicked = (row, event) => {
    const targetColumn = event.target.closest(".rdt_TableCell");
    if (!targetColumn) return;
    const columnIndex = Array.from(targetColumn.parentNode.children).indexOf(
      targetColumn
    );
    if (columnIndex === 1) return;
    setSelectedId(row?._id);
    navigate(
      adminPathUrl.dataLakeWorkforceDetail,

      {
        state: {
          id: row._id,
          skills: row.skills,
          currentPage: currentPage,
          skillfiltervalue: skillFilter,
          experiencefiltervalue: experienceFilter,
        },
      }
    );
  };

  const dataWithIndex = filteredData.map((row, index) => ({
    ...row,
    rowIndex: index,
  }));
  const conditionalRowStyles = [
    {
      when: (row) => row?.rowIndex == selectedId,
      style: {
        backgroundColor: "#02bea8",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  const handleCheckboxChange = (selectedRows) => {
    setSelectedRows(selectedRows.selectedRows);
  };

  const handleSkillFilterChange = (event) => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setSkillFilter(event ? event : "");
  };
  const handleExperienceFilterChange = (event) => {
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setExperienceFilter(event.target.value);
  };

  const handleVerificationFilterChange = (event) => {
    setVerificationFilter(event.target.value);
  };

  if (loading) {
    return <Loader />;
  }

  const subSkillNames = getTertiarySkillList(skillsetData.data);

  return (
    <div className="user_page">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <div className="mb-0">
          <input
            className="searchbar"
            type="search"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <IoSearchSharp className="searchbar-icon" size={44} />
        </div>

        <div className="filters_group text-end mt-2 col-md-8 gap-2">
          {/* <FormControl fullWidth className="d-none">
            <InputLabel id="skill-filter-label">Skill</InputLabel>
            <Select
              labelId="skill-filter-label"
              value={skillFilter}
              onChange={handleSkillFilterChange}
              label="Skill"
            >
              <MenuItem value="">All Skills</MenuItem>
              {subSkillNames &&
                subSkillNames?.sort()?.map((skill, index) => (
                  <MenuItem key={index} value={skill}>
                    {skill}
                  </MenuItem>
                ))}
            </Select>
          </FormControl> */}
          <div style={{ width: "45%" }}>
            <CustomSearchDropdown
              data={subSkillNames?.sort()}
              onChange={handleSkillFilterChange}
              value={skillFilter}
              label="Skill"
            />
          </div>
          <FormControl style={{ width: "25%" }}>
            <InputLabel id="experience-filter-label">Experience</InputLabel>
            <Select
              labelId="experience-filter-label"
              value={experienceFilter}
              onChange={handleExperienceFilterChange}
              label="Experience"
            >
              <MenuItem value="">All Experiences</MenuItem>
              <MenuItem value="1">0-1 years</MenuItem>
              <MenuItem value="2">1-2 years</MenuItem>
              <MenuItem value="4">2-4 years</MenuItem>
              <MenuItem value="5">4+ years</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className="d-none">
            <InputLabel id="verification-filter-label">Verification</InputLabel>
            <Select
              labelId="verification-filter-label"
              value={verificationFilter}
              onChange={handleVerificationFilterChange}
              label="Verification"
            >
              <MenuItem value="">All Verification Status</MenuItem>
              <MenuItem value="Unverified">Unverified</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>
          <button
            onClick={() =>
              navigate(adminPathUrl.addDataWorkforce, { state: skillList })
            }
            style={{ flex: "1" }}
            className="add-user-button m-0 d-flex align-items-center gap-2"
          >
            <FaUser /> <span>Add Workforce</span>
          </button>
        </div>
      </div>

      <div className="datatable-section manage-card">
        <DataTableComponent
          toRedirectPage={
            currentPage && (skillFilter || experienceFilter)
              ? currentPage
              : location?.state?.currentPage
          }
          title={
            <>{userDeleteCall?.deleteUser ? "Deleted User" : "Data Lake"}</>
          }
          data={dataWithIndex}
          columns={userColumns}
          selectedRows={selectedRows}
          clickedrow={handleRowClicked}
          checkboxchange={handleCheckboxChange}
          conditionalRowStyles={conditionalRowStyles}
          responsive={true}
          downloadBtns={true}
          downloadCSV={() =>
            downloadCsvPdfFile(
              typeForDownload.dataLake,
              fileFormatDownload.csv,
              "Manage Profiles"
            )
          }
          downloadPDF={() =>
            downloadCsvPdfFile(
              typeForDownload.dataLake,
              fileFormatDownload.pdf,
              "Manage Profiles"
            )
          }
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>

      <DeleteModalAdmin
        open={deleteModal}
        setOpen={setDeleteModal}
        heading={"Are you sure to delete this Worker"}
      />
    </div>
  );
};

export default DataLakeWorkForce;
