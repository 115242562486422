import React, { useState, useEffect, useRef } from "react";
import { Table } from "react-bootstrap";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { manPowerEnquirySlice } from "../../redux/manpowerEnquiry/manpowerEnquirySLice";
import Accordion from "react-bootstrap/Accordion";
import { toast } from "react-toastify";
import CustomDropdown from "../CustomDropdown";

const TableComponent = ({ theads, selectedCartItems }) => {
  const { categoryTable, formData } = useSelector(
    (state) => state.MANPOWERENQUIRY
  );
  const [data, setData] = useState(categoryTable);
  const [activeKey, setActiveKey] = useState(null);
  const dispatch = useDispatch();
  const accordionRef = useRef(null);

  const handleIncrement = (e, upperIndex, lowerIndex, field) => {
    e.preventDefault();
    const newData = [...data];
    const updatedSubSkills = [...newData[upperIndex].subSkills];

    updatedSubSkills[lowerIndex] = {
      ...updatedSubSkills[lowerIndex],
      [field]: (parseInt(updatedSubSkills[lowerIndex][field]) || 0) + 1,
    };

    newData[upperIndex] = {
      ...newData[upperIndex],
      subSkills: updatedSubSkills,
    };

    setData(newData);
    dispatch(
      manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(newData)
    );
  };

  const handleDecrement = (e, upperIndex, lowerIndex, field) => {
    e.preventDefault();
    const newData = [...data];
    const updatedSubSkills = [...newData[upperIndex].subSkills];

    updatedSubSkills[lowerIndex] = {
      ...updatedSubSkills[lowerIndex],
      [field]: (parseInt(updatedSubSkills[lowerIndex][field]) || 0) - 1,
    };
    updatedSubSkills[lowerIndex] = {
      ...updatedSubSkills[lowerIndex],
      days:
        updatedSubSkills[lowerIndex].workers === 0
          ? 0
          : updatedSubSkills[lowerIndex].days,
    };

    newData[upperIndex] = {
      ...newData[upperIndex],
      subSkills: updatedSubSkills,
    };

    if (
      selectedCartItems &&
      updatedSubSkills[lowerIndex].workers + 1 <= selectedCartItems
    ) {
      toast.info("Please unselect users first");
      return;
    }
    setData(newData);
    dispatch(
      manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(newData)
    );
  };

  const handleSelect = (value, upperIndex, lowerIndex, field) => {
    const newData = [...data];
    const updatedSubSkills = [...newData[upperIndex].subSkills];

    updatedSubSkills[lowerIndex] = {
      ...updatedSubSkills[lowerIndex],
      [field]: value,
    };
    newData[upperIndex] = {
      ...newData[upperIndex],
      subSkills: updatedSubSkills,
    };
    const updatedData = newData.map((item) => ({
      ...item,
      subSkills: item.subSkills.map((subSkill) => ({
        ...subSkill,
        workers: parseInt(subSkill.workers) || 0,
        days: parseInt(subSkill.days) || 0,
        // hours: parseInt(subSkill.hours) || 0,
      })),
    }));
    setData(updatedData);
    dispatch(
      manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(
        updatedData
      )
    );
  };

  const handleChange = (e, upperIndex, lowerIndex, field) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      const newData = [...data];
      const updatedSubSkills = [...newData[upperIndex].subSkills];

      updatedSubSkills[lowerIndex] = {
        ...updatedSubSkills[lowerIndex],
        [field]: value,
      };

      newData[upperIndex] = {
        ...newData[upperIndex],
        subSkills: updatedSubSkills,
      };
      const updatedData = newData.map((item) => ({
        ...item,
        subSkills: item.subSkills.map((subSkill) => ({
          ...subSkill,
          workers: parseInt(subSkill.workers) || 0,
          days: parseInt(subSkill.days) || 0,
          // hours: parseInt(subSkill.hours) || 0,
        })),
      }));
      setData(updatedData);
      dispatch(
        manPowerEnquirySlice.actions.categoryTableDataIncrementDecrement(
          updatedData
        )
      );
    }
  };
  useEffect(() => {
    dispatch(
      manPowerEnquirySlice.actions.setFormData({
        ...formData,
        categoryWorkerRequired: data,
      })
    );
  }, [data]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        accordionRef.current &&
        !accordionRef.current.contains(event.target)
      ) {
        accordionRef.current
          .querySelectorAll(".accordion-item")
          .forEach((item) => {
            const collapseElement = item.querySelector(".accordion-collapse");
            const buttonElement = item.querySelector(".accordion-button");

            const isExpanded = collapseElement.classList.contains("show");

            if (isExpanded) {
              collapseElement.classList.remove("show");
              buttonElement.setAttribute("aria-expanded", "false");
              buttonElement.classList.add("collapsed");
            }
          });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setData(categoryTable);
  }, [categoryTable]);

  const handleAccordionToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  return (
    <div ref={accordionRef} className="table-content">
      {data.map(({ newSkillName, subSkills }, upperindex) => (
        <Accordion
          key={upperindex}
          activeKey={activeKey}
          onSelect={handleAccordionToggle}
        >
          <Accordion.Item eventKey={String(upperindex)}>
            <Accordion.Header
              onClick={() => handleAccordionToggle(String(upperindex))}
            >
              {newSkillName}
            </Accordion.Header>
            <Accordion.Body className="p-0">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {theads.map((head) => (
                      <th className="table-heading text-center" key={head}>
                        <b>{head}</b>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {subSkills.map((item, lowerindex) => (
                    <tr className="table-td accordion" key={lowerindex}>
                      <td className="ps-2">{item.newSubSkillName}</td>
                      <CustomDropdown
                        label="Select Option"
                        selected={item?.categoryType?.name}
                        options={item.tertiarySkills}
                        onChange={(value) =>
                          handleSelect(
                            value,
                            upperindex,
                            lowerindex,
                            "categoryType"
                          )
                        }
                      />
                      <td className="text-center p-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            disabled={item.workers === 0}
                            onClick={(e) =>
                              handleDecrement(
                                e,
                                upperindex,
                                lowerindex,
                                "workers"
                              )
                            }
                            className={`table-plus-minus-icon border-end p-2 px-3 ${
                              item.workers === 0 && "disabled"
                            }`}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={item.workers}
                            onChange={(e) =>
                              handleChange(e, upperindex, lowerindex, "workers")
                            }
                            className="table-input p-2 text-center"
                            disabled={!item?.categoryType?.name}
                          />
                          <button
                            onClick={(e) =>
                              handleIncrement(
                                e,
                                upperindex,
                                lowerindex,
                                "workers"
                              )
                            }
                            className={`table-plus-minus-icon border-start p-2 px-3 ${
                              !item?.categoryType?.name && "disabled"
                            }`}
                            disabled={!item?.categoryType?.name}
                          >
                            +
                          </button>
                        </div>
                      </td>
                      <td className="text-center p-0">
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            disabled={item.workers === 0 || item.days === 0}
                            onClick={(e) =>
                              handleDecrement(e, upperindex, lowerindex, "days")
                            }
                            className={`table-plus-minus-icon border-end p-2 px-3 ${
                              (item.workers === 0 || item.days === 0) &&
                              "disabled"
                            }`}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={item.days}
                            onChange={(e) =>
                              handleChange(e, upperindex, lowerindex, "days")
                            }
                            className="table-input p-2 text-center"
                            disabled={item.workers === 0}
                          />
                          <button
                            disabled={item.workers === 0}
                            onClick={(e) =>
                              handleIncrement(e, upperindex, lowerindex, "days")
                            }
                            className={`table-plus-minus-icon border-start p-2 px-3 ${
                              item.workers === 0 && "disabled"
                            }`}
                          >
                            +
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );
};

export default TableComponent;
