import React, { useEffect, useState } from "react";
import banner from "../../assests/images/aboutbanner.jpg";
import "./index.css";
import WhatWeDo from "./whatWeDo";
import WhatSetUspart from "./whatSetUspart";
import Team from "./team";
import ClientReview from "../../components/clientReview/clientReview";
import { LogoSvg } from "../../assests/svg";
import { Navigate, useNavigate } from "react-router-dom";
import { pathUrl } from "../../shared/pathUrl";
import {
  getAboutUsService,
  getReviewsService,
} from "../../services/admin/manageworkforce/manageworkforceservices";

export default function AboutUs() {
  const navigate = useNavigate();
  const [aboutUsData, setAboutUsData] = useState(null);
  const [reviews, setReviews] = useState([]);

  // GET the review data from the backend
  const getReviewsData = async () => {
    try {
      const response = await getReviewsService();
      if (response.status) {
        setReviews(response.data);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  // Fetch about us data from the backend
  const getAboutUsData = async () => {
    try {
      const response = await getAboutUsService();
      if (response.status && response.data.length > 0) {
        setAboutUsData(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching about us data:", error);
    }
  };

  useEffect(() => {
    getReviewsData();
    getAboutUsData();
  }, []);

  return (
    <>
      <section className="about_us_banner">
        {aboutUsData && (
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                {/* <div className="about_logo">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(pathUrl.homePage)}
                  >
                    {LogoSvg()}
                  </span>
                </div>
                <span className="bread-crumb py-3 d-block">
                  Home / <span className="bread-crumb-extend-text">About</span>
                </span> */}
                <div className="about-us-heading">
                  {/* <h3 className="easily_find_heading"> Welcome to</h3> */}
                  <h3 className="great_worker_heading">
                    {aboutUsData.about_us_title}{" "}
                  </h3>
                  <p className="paragraph">
                    {/* Render about_us_content HTML */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: aboutUsData.about_us_content,
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-end pe-0">
                <img
                  className="img-fluid"
                  src={aboutUsData.about_us_pic}
                  alt="About Us Banner"
                />
              </div>
            </div>
          </div>
        )}
      </section>

      <WhatWeDo />
      <WhatSetUspart />
      {/* <Team /> */}
      <div className="pb-5" style={{ marginBottom: "45px" }}>
        <ClientReview reviews={reviews} />
      </div>
    </>
  );
}
