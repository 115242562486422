import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./index.css";
import {
  addHomepageWeplacedService,
  getHomepageWeplacedService,
} from "../../../services/admin/manageworkforce/manageworkforceservices";
import { toast } from "react-toastify";
const ManageContactUs = () => {
  const [localState, setLocalState] = useState({
    inputData: {
      email: "",
      phone: "",
      linkedin: "",
    },
    inputError: {
      email: "",
      phone: "",
      linkedin: "",
    },
    loading: false,
    manageExistingData: null,
  });
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (
      (name == "email" && value?.length > 40) ||
      (name == "phone" && value?.length > 20) ||
      (name == "linkedin" && value?.length > 48)
    ) {
      return;
    }
    setLocalState((prev) => ({
      ...prev,
      inputData: {
        ...prev.inputData,
        [name]: value,
      },
    }));
    if (name == "email") {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          email: value?.length > 0 ? "" : "Email is required",
        },
      }));
    }
    if (name == "phone") {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          phone: value?.length > 0 ? "" : "Phone is required",
        },
      }));
    }
    if (name == "linkedin") {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          linkedin: value?.length > 0 ? "" : "Linkedin is required",
        },
      }));
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !localState?.inputData?.email &&
      !localState?.inputData?.phone &&
      !localState?.inputData?.linkedin
    ) {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          email: "Email is required",
          phone: "Phone is required",
          linkedin: "Linkedin is required",
        },
      }));
    } else if (!localState?.inputData?.email) {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          email: "Email is required",
        },
      }));
    } else if (!localState?.inputData?.phone) {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          phone: "Phone is required",
        },
      }));
    } else if (!localState?.inputData?.linkedin) {
      setLocalState((prev) => ({
        ...prev,
        inputError: {
          ...prev.inputError,
          linkedin: "Linked in is required",
        },
      }));
    } else {
      console.log(localState?.inputData);
      const payload = {
        id: localState?.manageExistingData?._id,
        website: localState?.inputData.email,
        linkedIn_link: localState?.inputData?.linkedin,
        phone: localState?.inputData?.phone,
      };
      try {
        setLocalState((prev) => ({
          ...prev,
          loading: true,
        }));
        const res = await addHomepageWeplacedService(
          localState?.manageExistingData?._id,
          payload
        );
        if (res?.status) {
          toast.success(res?.message);
          fetchHomePage();
          setLocalState((prev) => ({
            ...prev,
            loading: false,
          }));
        }
      } catch (error) {
        console.log(error);
        setLocalState((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    }
  };

  function onChangeToNumber(value) {
    return String(value)?.replace(/[^\d]/g, "").trimStart();
  }

  const fetchHomePage = async () => {
    try {
      const response = await getHomepageWeplacedService();
      if (response.status) {
        const homepageData = response?.data[0];
        setLocalState((prev) => ({
          ...prev,
          manageExistingData: homepageData,
        }));
      }
    } catch (error) {
      console.error("Error fetching homepage content:", error);
    }
  };
  useEffect(() => {
    fetchHomePage();
  }, []);
  useEffect(() => {
    if (localState?.manageExistingData) {
      setLocalState((prev) => ({
        ...prev,
        inputData: {
          ...prev.inputData,
          email: localState?.manageExistingData?.website || "",
          phone: localState?.manageExistingData?.phone || "",
          linkedin: localState?.manageExistingData?.linkedIn_link || "  ",
        },
      }));
    }
  }, [localState?.manageExistingData]);

  return (
    <div className="manage_contactus">
      <h5>Manage Contact</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <div className="d-flex justify-content-between align-items-center">
            <Form.Label>Website Email</Form.Label>
            <h6 className="fw-bold">
              {localState?.inputData?.email?.length}/40{" "}
            </h6>
          </div>
          <Form.Control
            type="email"
            name="email"
            value={localState?.inputData?.email}
            onChange={handleInputChange}
            placeholder="Enter email"
          />
          {localState?.inputError?.email && (
            <small className="mt-1 text-danger">
              {localState?.inputError?.email}{" "}
            </small>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPhone">
          <div className="d-flex justify-content-between align-items-center">
            <Form.Label>Phone</Form.Label>
            <h6 className="fw-bold">
              {localState?.inputData?.phone?.length}/20{" "}
            </h6>
          </div>
          <Form.Control
            type="text"
            placeholder="Phone"
            name="phone"
            value={localState?.inputData?.phone}
            onChange={handleInputChange}
          />
          {localState?.inputError?.phone && (
            <small className="mt-1 text-danger">
              {localState?.inputError?.phone}{" "}
            </small>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicLinkedin">
          <div className="d-flex justify-content-between align-items-center">
            <Form.Label>LinkedIn Link</Form.Label>
            <h6 className="fw-bold">
              {localState?.inputData?.linkedin?.length}/48{" "}
            </h6>
          </div>
          <Form.Control
            type="text"
            placeholder="Linked In"
            name="linkedin"
            value={localState?.inputData?.linkedin}
            onChange={handleInputChange}
          />
          {localState?.inputError?.linkedin && (
            <small className="mt-1 text-danger">
              {localState?.inputError?.linkedin}{" "}
            </small>
          )}
        </Form.Group>

        <Button variant="primary" type="submit">
          {localState?.loading ? "...Loading" : "Save"}
        </Button>
      </Form>
    </div>
  );
};

export default ManageContactUs;
